const createPurchasesStore = (set) => ({
  purchasesList: {},
  purchasesLoading: false,
  setPurchasesList: (groupId, list) =>
    set((state) => ({
      purchases: {
        ...state.purchases,
        purchasesList: {
          ...state.purchases.purchasesList,
          [groupId]: list,
        },
      },
    })),
  setPurchasesLoading: (purchasesLoading) =>
    set((state) => ({
      purchases: {
        ...state.purchases,
        purchasesLoading,
      },
    })),

  reset: (state) => set({ purchases: { ...state, purchasesList: {} } }),
});

export default createPurchasesStore;
