/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ConfigProvider, Modal, Spin, Table } from "antd";
import useMembers from "./useMembers";
import "./Members.css";
import MemberFilters from "./MemberFilters/MemberFilters";
import MemberActions from "./MemberActions/MemberActions";
import EditMember from "../EditMemberModal/EditMember";

const Members = () => {
  const {
    columns,
    filteredTableData,
    handleSearchInputChange,
    handleFilterChange,
    currentPackages,
    formLayout,
    groupData,
    handleDropdownChange,
    filters,
    membersLoading,
    editableRecord,
    editableRecordId,
    handleModalOpen,
    saveDetail,
    linkedMember,
    showAddMemberModal,
    addMember,
    setShowAddMemberModal,
    registrationData,
    verifyAddMemberLoading,
    addMemberLoading,
  } = useMembers();

  return (
    <div className="members-table-container">
      <div className="members-action-bar-container">
        <MemberActions
          tableData={filteredTableData}
          addMemberLoading={addMemberLoading}
        />
        <MemberFilters
          handleSearchInputChange={handleSearchInputChange}
          handleFilterChange={handleFilterChange}
          packages={currentPackages}
          chapters={formLayout?.[groupData?.id]?.chapters}
          handleDropdownChange={handleDropdownChange}
          filters={filters}
        />
      </div>

      {/* <p>Total Records: {filteredTableData?.length}</p> */}

      <div style={{ height: "50vh" }}>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            bordered
            columns={columns}
            headerBorderRadius={0}
            loading={membersLoading}
            dataSource={filteredTableData}
            sticky={{
              offsetHeader: 0, // This should match the top position where you want the header to stick
              getContainer: () => document.body, // Important for ensuring sticky works correctly
            }}
            scroll={{ x: "max-content" }}
            rowKey="id"
            bodyStyle={{
              fontSize: window.screen.width > 500 ? "18px" : "12px",
              backgroundColor: "#ffffff",
            }}
            pagination={{
              defaultPageSize: 100,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
              position: ["bottomLeft"],
            }}
          />
        </ConfigProvider>
      </div>

      <EditMember
        editableRecord={editableRecord}
        editableRecordId={editableRecordId}
        handleModalOpen={handleModalOpen}
        groupId={groupData?.id}
        saveDetail={saveDetail}
        linkedMember={linkedMember}
      />

      <Modal
        title={<h5>Confirm Registration Request</h5>}
        width={700}
        centered
        open={showAddMemberModal}
        onOk={() => {
          setShowAddMemberModal(false);
          addMember();
        }}
        onCancel={() => {
          setShowAddMemberModal(false);
        }}
      >
        <div>
          {verifyAddMemberLoading ? (
            <div
              className="flex-center"
              style={{ width: "100%", height: "3rem" }}
            >
              <Spin />
            </div>
          ) : (
            <div>
              <p style={{ margin: 0, padding: 0 }}>
                <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Name:{" "}
                </span>{" "}
                <span style={{ fontSize: "1rem" }}>
                  {registrationData?.name || ""}
                </span>
              </p>
              <p style={{ margin: 0 }}>
                <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Email:
                </span>{" "}
                <span style={{ fontSize: "1rem" }}>
                  {registrationData?.email || ""}
                </span>
              </p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Members;
