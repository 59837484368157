import React, { useState } from "react";
import { Input, Select } from "antd";
import "./EventsFilters.css";

import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import {
  EVENTS_ALL_EVENT,
  EVENTS_ALL_REPORT,
  MEMBER_BY_EVENT_REPORT,
  MEMBER_DETAILS_REPORT,
} from "../../../../../constants/leaderboard";
import { isMobile } from "../../../../../utils/helper";

const EventsFilters = ({
  handleDropdownChange,
  showEventsFilter,
  eventsDropdownList,
  handleSearch,
  seasons,
  search,
  filters,
  eventsLoading,
  searchType,
}) => {
  const REPORTS_FILTERS = [
    MEMBER_BY_EVENT_REPORT,
    MEMBER_DETAILS_REPORT,
    EVENTS_ALL_REPORT,
  ];

  const updatedSeasonsFilter =
    seasons?.map((item) => {
      return {
        value: item,
        label: <span>{item}</span>,
      };
    }) || [];

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  //   const [showAllFilters, setShowAllFilters] = useState(false);

  return (
    <div>
      {isMobile() ? (
        <div
          className="event-filters-header"
          onClick={toggleExpand}
          style={isExpanded ? { borderRadius: "0%" } : undefined}
        >
          <span>Filters</span>
          {isExpanded ? (
            <UpOutlined style={{ marginLeft: "auto" }} />
          ) : (
            <DownOutlined style={{ marginLeft: "auto" }} />
          )}
        </div>
      ) : null}

      {(isExpanded || !isMobile()) && (
        <div className="event-filters-container">
          <div>
            <Input
              placeholder={`Search ${searchType === "members" ? "name" : "event"}`}
              prefix={<SearchOutlined />}
              style={{ width: isMobile() ? "100%" : "15rem" }}
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
              disabled={eventsLoading}
            />
          </div>

          <div className="event-filters">
            <Select
              className="event-filter-input"
              defaultActiveFirstOption
              popupMatchSelectWidth={false}
              options={REPORTS_FILTERS?.map((item) => ({
                value: item,
                label: <span>{item}</span>,
              }))}
              value={filters.report}
              onChange={(val) => handleDropdownChange("report", val)}
              disabled={eventsLoading}
              loading={eventsLoading}
            />

            {showEventsFilter && (
              <Select
                className="event-filter-input"
                defaultActiveFirstOption
                popupMatchSelectWidth={false}
                options={[
                  {
                    value: EVENTS_ALL_EVENT,
                    label: <span>{EVENTS_ALL_EVENT}</span>,
                  },
                  ...eventsDropdownList,
                ]}
                value={filters.event}
                onChange={(val) => handleDropdownChange("event", val)}
                disabled={eventsLoading}
                loading={eventsLoading}
              />
            )}

            <Select
              className="event-filter-input"
              defaultActiveFirstOption
              popupMatchSelectWidth={false}
              options={updatedSeasonsFilter}
              value={filters.season}
              onChange={(val) => handleDropdownChange("season", val)}
              disabled={eventsLoading}
              loading={eventsLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventsFilters;
