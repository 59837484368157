import { Alert, Spin, Table, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import StoreItem from "../../../models/admin/StoreItem";
import { db, Firebase } from "../../../services/firebaseService/connection";
import { uploadImage } from "../../../services/firebaseService/endPoints/admin/members";
import {
  addItem,
  getItem,
  updateItem,
} from "../../../services/firebaseService/endPoints/admin/store";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import ImageUploadButton from "../website/ImageUploaderButton";
import {
  getCurrencyUnicode,
  postUpdateCDNUtil,
} from "../../../helperFunctions/util";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  writeBatch,
} from "@firebase/firestore";
import { verifyStripeAccountId } from "../../../utils/errors";
import { withRouter } from "../../../utils/helper";

const statuses = ["Live", "Inactive"].map((value) => ({
  key: value.toLowerCase(),
  value,
}));
const quantityPerPriceOption = [
  { key: "single", value: "Set single quantity/price combination for product" },
  {
    key: "perCombination",
    value: "Set quantity and price by size or size/color combination",
  },
];

const displayOptions = [
  //     {
  //     label: 'App',
  //     value: 'app'
  // },
  {
    label: "Web",
    value: "web",
  },
  {
    label: "Registration",
    value: "registration",
  },
];

const allTshirtSizes = [
  "One Size",

  "Youth Small",

  "Youth Medium",

  "Youth Large",

  "Youth XL",

  "Adult XS",

  "Adult Small",

  "Adult Medium",

  "Adult Large",

  "Adult XL",

  "Adult 2XL",

  "Adult 3XL",

  "Adult 4XL",

  "Adult 5XL",

  "Women’s XS",

  "Women’s Small",

  "Women’s Medium",

  "Women’s Large",

  "Women’s XL",

  "Women’s 2XL",

  "Women’s 3XL",

  "Small",

  "Medium",

  "Large",

  "XL",
];

const comboValueTemplate = {
  availableColors: [],
  availableSizes: [],
  price: "",
  nonMemberPrice: "",
  salePrice: "",
  nonMemberSalePrice: "",
  shippingCost: "",
  unitCost: "",
  quantity: "",
};

class CreateItem extends React.Component {
  state = {
    status: "",
    displayOn: [],
    name: "",
    desc: "",
    hasSize: false,
    showSizes: false,
    comboValues: [JSON.parse(JSON.stringify(comboValueTemplate))],
    productPrimaryImage: {
      image: "",
      color: "",
    },
    productOptionalImages: [null, null, null, null],
    errorInForm: {
      isError: false,
      visible: false,
      message: "",
    },
    quantityPriceOption: "",
    sku: "",
    showColor: false,
    hasColor: "",
    allColors: [],
    loading: false,
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = {
        ...prevState.errorInForm,
      };
      errorInForm.visible = false;
      return {
        errorInForm,
      };
    });
  };

  handleSubmit = async () => {
    if (
      !verifyStripeAccountId(
        this.props?.adminData?.data?.paymentDetails?.stripeAccountId
      )
    ) {
      return;
    }

    this.setState({ loading: true });
    let stateData = this.state;
    if (this.state.productPrimaryImage) {
      if (typeof this.state.productPrimaryImage.image !== "string") {
        await uploadImage(
          this.state.productPrimaryImage.image,
          `/store/${this.props.currentGroup}/items/${Date.now()}`
        )
          .then(({ downloadUrl }) => downloadUrl)
          .then((url) => {
            stateData.productPrimaryImage.image = url;
          });
      }
    } else {
      window.scrollTo(0, 0);
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message: "Add product image.",
        },
        isLoading: false,
      });
      window.scrollTo(0, 0);
      setTimeout(this.handleErrorMessageClose, 5000);
      return;
    }

    let promises = stateData.productOptionalImages.map((image, index) => {
      if (image && image.image && typeof image.image !== "string") {
        return uploadImage(
          this.state.productOptionalImages[index] &&
            this.state.productOptionalImages[index].image,
          `/store/${this.props.currentGroup}/items/${moment.now()}`,
          index
        ).then(({ downloadUrl, index }) => {
          stateData.productOptionalImages[index].image = downloadUrl;
        });
      } else {
        if (!image || !image.image) {
          return new Promise((resolve, reject) => {
            resolve(null);
          }).then((url) => {
            if (stateData.productOptionalImages[index])
              stateData.productOptionalImages[index].image = url;
          });
        }
      }
    });

    await Promise.all(promises);

    stateData.productOptionalImages = stateData.productOptionalImages.filter(
      (image) => image && image.image
    );

    let data = new StoreItem();
    data.toDataSet(this.state);

    data = JSON.parse(JSON.stringify(data));

    if (data.hasError) {
      window.scrollTo(0, 0);
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message: data.hasError.message,
        },
        isLoading: false,
      });
      window.scrollTo(0, 0);
      setTimeout(this.handleErrorMessageClose, 5000);
    } else {
      if (this.props.router.params.id) {
        await updateItem(
          this.props.router.params.id,
          this.props.currentGroup,
          data
        );
      } else {
        let batch = writeBatch(db);
        let docRef = collection(db, "store", this.props.currentGroup, "items");

        await getDocs(docRef).then((snap) => {
          return snap.docs.map((doc) => {
            return batch.update(doc.ref, { sortIndex: increment(1) });
          });
        });

        await batch.commit();

        await addItem(this.props.currentGroup, data);
      }
      postUpdateCDNUtil(this.props.currentGroup, "store");
      this.props.router.navigate("/admin/store/merch");
    }
    this.setState({ loading: false });
  };

  fetchData = () => {
    if (this.props.router.params.id) {
      getItem(this.props.router.params.id, this.props.currentGroup).then(
        (data) => {
          let stateData = new StoreItem();
          stateData.fromDataSet(data);
          stateData = JSON.parse(JSON.stringify(stateData));

          this.setState({
            ...stateData,
          });
        }
      );
    }
  };

  componentDidMount() {
    if (this.props.router.params.id) {
      this.fetchData();
    }
    getDoc(doc(db, "store", this.props.currentGroup)).then((doc) => {
      if (doc.exists()) {
        let data = doc.data();
        let allColors = data.colors;
        if (allColors && Array.isArray(allColors)) {
          this.setState({
            allColors,
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.currentGroup !== this.props.currentGroup) {
    //     this.props.router.navigate('/admin/store')
    // }
  }

  render() {
    let allSelectedColors = [];
    this.state.comboValues.forEach((combo) => {
      allSelectedColors.push(...combo.availableColors);
    });
    let selectedColors = Array.from(new Set(allSelectedColors));

    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div className="col">
        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              // className="mb-3"
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}

        <div className=" border-box" style={{ padding: "30px 16px" }}>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "black",
              }}
            >
              Add Product
              <a></a>
            </li>
          </ul>
          <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-3">
            <div className="col">
              <p>
                Products can be set to show across your Chant website, a
                standalone web page, the app and with registration packages.
                Ensure you have first completed the 'Store Settings' form.
              </p>
            </div>
          </div>
          <div className="p-3 px-3">
            <div
              className="p-2"
              style={{
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 100px 5px #dddddd",
              }}
            >
              <div className="col-md-8">
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="mb-3"
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontWeight: "bold",
                    }}
                  >
                    General
                  </span>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-4">
                    Status<span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <FSelect
                      value={this.state.status}
                      onChange={(e) => {
                        this.setState({ status: e.target.value });
                      }}
                      dataList={statuses}
                      dataKey="key"
                      dataValue="value"
                      placeholder="---Select status---"
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="mt-2"
                >
                  <div className="col-md-4">
                    Display On<span style={{ color: "red" }}>*</span>
                    <Tooltip
                      placement="topLeft"
                      title={() => (
                        <div style={{ width: 400 }}>
                          Required if Status set to ‘Live’. Web & Registration
                          only relevant if group uses those Chant features.
                        </div>
                      )}
                    >
                      <span className="border-0">
                        &nbsp;<i className="fa fa-question-circle-o"></i>
                      </span>
                    </Tooltip>
                  </div>
                  <div className="col-md-8">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-8">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <ul
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "18px",
                              width: "100%",
                              listStyleType: "none",
                              paddingInlineStart: 0,
                            }}
                          >
                            {displayOptions.map((option) => (
                              <li
                                style={{
                                  display: "flex",
                                  gap: "8px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {option.label}
                                <input
                                  checked={this.state.displayOn.includes(
                                    option.value
                                  )}
                                  onChange={() => {
                                    let isPresent =
                                      this.state.displayOn.includes(
                                        option.value
                                      );
                                    if (isPresent) {
                                      let displayOn = this.state.displayOn;
                                      displayOn.splice(
                                        displayOn.indexOf(option.value),
                                        1
                                      );
                                      this.setState({ displayOn: displayOn });
                                    } else {
                                      let displayOn = this.state.displayOn;
                                      displayOn.push(option.value);
                                      this.setState({ displayOn });
                                    }
                                  }}
                                  type="checkbox"
                                  className="ml-1"
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="mt-2"
                >
                  <div className="col-md-4">
                    Product Name<span style={{ color: "red" }}>*</span>
                    <Tooltip
                      placement="topLeft"
                      title={() => (
                        <div style={{ width: 400 }}>
                          Limit of 50 characters.
                        </div>
                      )}
                    >
                      <span className="border-0">
                        &nbsp;<i className="fa fa-question-circle-o"></i>
                      </span>
                    </Tooltip>
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "-4px" }}>
                    <FInput
                      value={this.state.name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      maxLength={50}
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="mt-2"
                >
                  <div className="col-md-4">
                    Description
                    <Tooltip
                      placement="topLeft"
                      title={() => (
                        <div style={{ width: 400 }}>
                          Limit of 500 characters.
                        </div>
                      )}
                    >
                      <span className="border-0">
                        &nbsp;<i className="fa fa-question-circle-o"></i>
                      </span>
                    </Tooltip>
                  </div>
                  <div className="col-md-8">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-4"></div>
                      <textarea
                        rows={2}
                        value={this.state.desc}
                        onChange={(e) => {
                          this.setState({ desc: e.target.value });
                        }}
                        maxLength={500}
                        className="form-control form-control-sm col-8"
                      />
                    </div>
                  </div>
                </div>

                {/* <div className='mt-2'>
                                    <div className='col-md-4'>
                                        SKU
                                        <Tooltip placement="topLeft" title={() => <div style={{ width: 400 }}>
                                            <p>Must be unique. Limit of 25 characters. Only hyphen allowed as special character. Color/Size variations automatically given extensions (-1, -2) if quantity and price set by size/color. Example: SCARF-2022.</p>
                                            <p>If no SKU entered, Stripe will create default value</p>
                                        </div>}>
                                            <span className="border-0">
                                                &nbsp;<i className="fa fa-question-circle-o"></i>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <input
                                                className='form-control form-control-sm col-3 no-arrow-field'
                                                type='text'
                                                onChange={(e) => {
                                                    let value = e.target.value;

                                                    this.setState({
                                                        sku: value
                                                    });
                                                }
                                                }
                                                value={this.state.quantity}
                                                step={1}
                                            />
                                        </div>
                                    </div>
                                </div> */}

                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="mt-2"
                >
                  <div className="col-md-4">
                    Quantity/Price<span style={{ color: "red" }}>*</span>
                    <Tooltip
                      placement="topLeft"
                      title={() => (
                        <div style={{ width: 400 }}>
                          Create products with a single quantity and price
                          values (scarf) or set quantity and price by size
                          and/or color.
                        </div>
                      )}
                    >
                      <span className="border-0">
                        &nbsp;<i className="fa fa-question-circle-o"></i>
                      </span>
                    </Tooltip>
                  </div>
                  <div className="col-md-8">
                    <FSelect
                      value={this.state.quantityPriceOption}
                      onChange={(e) => {
                        let value = e.target.value;
                        let hasColor = this.state.hasColor;
                        let hasSize = this.state.hasSize;
                        let comboValues = this.state.comboValues;

                        if (value === "single") {
                          comboValues = [
                            JSON.parse(JSON.stringify(comboValueTemplate)),
                          ];
                        }
                        if (value === "perCombination") {
                          hasColor = true;
                          hasSize = true;
                        }
                        this.setState({
                          quantityPriceOption: value,
                          hasColor,
                          hasSize,
                          comboValues,
                        });
                      }}
                      dataList={quantityPerPriceOption}
                      dataKey="key"
                      dataValue="value"
                      placeholder="---Select option---"
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.quantityPriceOption && (
              <div
                className="mt-4 p-2"
                style={{
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 100px 5px #dddddd",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div className="col-md-12">
                  <div
                    style={{ display: "flex", flexWrap: "wrap" }}
                    className="mb-3"
                  >
                    <span
                      style={{
                        fontSize: 19,
                        fontWeight: "bold",
                      }}
                    >
                      Details
                    </span>
                  </div>

                  {this.getRenderFieldPerQuantityPriceOption()}
                </div>
              </div>
            )}

            <div
              className="mt-4 p-2"
              style={{
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 100px 5px #dddddd",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div className="col-md-8">
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="mb-3"
                >
                  <span
                    style={{
                      fontSize: 19,
                      fontWeight: "bold",
                    }}
                  >
                    Images
                  </span>
                </div>
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="mt-3"
                >
                  <div className="col-md-4">
                    Product Images<span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="col-md-8">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div className="col-md-8">
                            <ImageUploadButton
                              images={[this.state.productPrimaryImage.image]}
                              onSave={(images) => {
                                this.setState({
                                  productPrimaryImage: { image: images[0] },
                                });
                              }}
                              text="Primary Image"
                              aspectRatio={1}
                            />
                          </div>
                          {selectedColors.length > 0 && (
                            <div className="col-md-4">
                              <select
                                value={this.state.productPrimaryImage.color}
                                onChange={(e) => {
                                  let productPrimaryImage =
                                    this.state.productPrimaryImage;
                                  productPrimaryImage.color = e.target.value;
                                  this.setState({ productPrimaryImage });
                                }}
                              >
                                <option value={""} key="select">
                                  --Select color--
                                </option>
                                {selectedColors.map((color) => (
                                  <option key={color} value={color}>
                                    {color}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>
                        <div
                          style={{ display: "flex", flexWrap: "wrap" }}
                          className="mt-2"
                        >
                          <div className="col-md-8">
                            <div className="d-flex">
                              <ImageUploadButton
                                images={[
                                  this.state.productOptionalImages[0] &&
                                    this.state.productOptionalImages[0].image,
                                ]}
                                onSave={(images) => {
                                  let productOptionalImages =
                                    this.state.productOptionalImages;
                                  productOptionalImages[0] = {
                                    image: images[0],
                                  };
                                  this.setState({
                                    productOptionalImages,
                                  });
                                }}
                                text="Additional Image"
                                aspectRatio={1}
                              />
                              <span>(optional)</span>
                            </div>
                          </div>
                          {selectedColors.length > 0 && (
                            <div className="col-md-4">
                              <select
                                value={
                                  this.state.productOptionalImages[0] &&
                                  this.state.productOptionalImages[0].color
                                }
                                onChange={(e) => {
                                  let productOptionalImages =
                                    this.state.productOptionalImages;
                                  productOptionalImages[0] =
                                    productOptionalImages[0] || {};
                                  productOptionalImages[0].color =
                                    e.target.value;
                                  this.setState({ productOptionalImages });
                                }}
                              >
                                <option value={""} key="select">
                                  --Select color--
                                </option>
                                {selectedColors.map((color) => (
                                  <option key={color} value={color}>
                                    {color}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>
                        <div
                          style={{ display: "flex", flexWrap: "wrap" }}
                          className="mt-2"
                        >
                          <div className="col-md-8">
                            <div className="d-flex">
                              <ImageUploadButton
                                images={[
                                  this.state.productOptionalImages[1] &&
                                    this.state.productOptionalImages[1].image,
                                ]}
                                onSave={(images) => {
                                  let productOptionalImages =
                                    this.state.productOptionalImages;
                                  productOptionalImages[1] = {
                                    image: images[0],
                                  };
                                  this.setState({
                                    productOptionalImages,
                                  });
                                }}
                                text="Additional Image"
                                aspectRatio={1}
                              />
                              <span>(optional)</span>
                            </div>
                          </div>
                          {selectedColors.length > 0 && (
                            <div className="col-md-4">
                              <select
                                value={
                                  this.state.productOptionalImages[1] &&
                                  this.state.productOptionalImages[1].color
                                }
                                onChange={(e) => {
                                  let productOptionalImages =
                                    this.state.productOptionalImages;
                                  productOptionalImages[1] =
                                    productOptionalImages[1] || {};
                                  productOptionalImages[1].color =
                                    e.target.value;
                                  this.setState({ productOptionalImages });
                                }}
                              >
                                <option value={""} key="select">
                                  --Select color--
                                </option>
                                {selectedColors.map((color) => (
                                  <option key={color} value={color}>
                                    {color}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>
                        <div
                          style={{ display: "flex", flexWrap: "wrap" }}
                          className="mt-2"
                        >
                          <div className="col-md-8">
                            <div className="d-flex">
                              <ImageUploadButton
                                images={[
                                  this.state.productOptionalImages[2] &&
                                    this.state.productOptionalImages[2].image,
                                ]}
                                onSave={(images) => {
                                  let productOptionalImages =
                                    this.state.productOptionalImages;
                                  productOptionalImages[2] = {
                                    image: images[0],
                                  };
                                  this.setState({
                                    productOptionalImages,
                                  });
                                }}
                                text="Additional Image"
                                aspectRatio={1}
                              />
                              <span>(optional)</span>
                            </div>
                          </div>
                          {selectedColors.length > 0 && (
                            <div className="col-md-4">
                              <select
                                value={
                                  this.state.productOptionalImages[2] &&
                                  this.state.productOptionalImages[2].color
                                }
                                onChange={(e) => {
                                  let productOptionalImages =
                                    this.state.productOptionalImages;
                                  productOptionalImages[2] =
                                    productOptionalImages[2] || {};
                                  productOptionalImages[2].color =
                                    e.target.value;
                                  this.setState({ productOptionalImages });
                                }}
                              >
                                <option value={""} key="select">
                                  --Select color--
                                </option>
                                {selectedColors.map((color) => (
                                  <option key={color} value={color}>
                                    {color}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>
                        <div
                          style={{ display: "flex", flexWrap: "wrap" }}
                          className="mt-2"
                        >
                          <div className="col-md-8">
                            <div className=" d-flex">
                              <ImageUploadButton
                                images={[
                                  this.state.productOptionalImages[3] &&
                                    this.state.productOptionalImages[3].image,
                                ]}
                                onSave={(images) => {
                                  let productOptionalImages =
                                    this.state.productOptionalImages;
                                  productOptionalImages[3] = {
                                    image: images[0],
                                  };
                                  this.setState({
                                    productOptionalImages,
                                  });
                                }}
                                text="Additional Image"
                                aspectRatio={1}
                              />
                              <span>(optional)</span>
                            </div>
                          </div>
                          {selectedColors.length > 0 && (
                            <div className="col-md-4">
                              <select
                                value={
                                  this.state.productOptionalImages[3] &&
                                  this.state.productOptionalImages[3].color
                                }
                                onChange={(e) => {
                                  let productOptionalImages =
                                    this.state.productOptionalImages;
                                  productOptionalImages[3] =
                                    productOptionalImages[3] || {};
                                  productOptionalImages[3].color =
                                    e.target.value;
                                  this.setState({ productOptionalImages });
                                }}
                              >
                                <option value={""} key="select">
                                  --Select color--
                                </option>
                                {selectedColors.map((color) => (
                                  <option key={color} value={color}>
                                    {color}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-2">
              <button
                className="btn btn-success"
                style={{
                  backgroundColor: primaryColor,
                  borderColor: primaryColor,
                }}
                tabIndex="14"
                type="success"
                onClick={this.handleSubmit}
              >
                {this.state.loading ? (
                  <Spin size="small" spinning={true} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getRenderFieldPerQuantityPriceOption() {
    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);
    if (this.state.quantityPriceOption === "single") {
      return (
        <>
          <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
            <div className="col-md-4">Sizes</div>
            <div className="col-md-4">
              <div
                style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}
                className="align-items-center"
              >
                {/* <div className="col-md-4"></div> */}
                <input
                  type="checkbox"
                  checked={this.state.hasSize}
                  onChange={() => {
                    this.setState((state) => {
                      if (!state.hasSize) {
                        return { hasSize: true };
                      } else {
                        // let comboValues = this.state.comboValues;
                        // comboValues[0].availableSizes = [];
                        return {
                          hasSize: false,
                          showSizes: false,
                          // comboValues,
                        };
                      }
                    });
                  }}
                />
                {this.state.hasSize && (
                  <div>
                    <span
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        width: "max-content",
                      }}
                      onClick={() => {
                        this.setState((state) => ({
                          showSizes: !state.showSizes,
                        }));
                      }}
                    >
                      Select available sizes
                    </span>
                  </div>
                )}
              </div>
            </div>
            {this.state.comboValues[0]?.availableSizes?.length > 0 && (
              <div className="col-md-4">
                <span
                  style={{ fontSize: "12px" }}
                  onClick={() => {
                    this.setState((state) => ({
                      showSizes: !state.showSizes,
                    }));
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>Selected:</span>{" "}
                  {this.state.comboValues[0].availableSizes.join(", ")}
                </span>
              </div>
            )}
          </div>

          {this.state.showSizes && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-8">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-4"></div>
                  <div className="col-md-8" style={{ paddingLeft: "2rem" }}>
                    <ul style={{ listStyleType: "none" }}>
                      {allTshirtSizes.map((size) => (
                        <li style={{ display: "flex", gap: "8px" }}>
                          <input
                            checked={
                              this.state.comboValues[0].availableSizes
                                ? this.state.comboValues[0].availableSizes.includes(
                                    size
                                  )
                                : false
                            }
                            onChange={() => {
                              let availableSizes =
                                this.state.comboValues[0].availableSizes;
                              if (Array.isArray(availableSizes)) {
                                if (availableSizes.includes(size)) {
                                  availableSizes.splice(
                                    availableSizes.indexOf(size),
                                    1
                                  );
                                } else {
                                  availableSizes.push(size);
                                }
                              }
                              let comboValues = this.state.comboValues;
                              comboValues[0].availableSizes = availableSizes;

                              this.setState({ comboValues });
                            }}
                            type="checkbox"
                          />
                          <label className="ml-1">{size}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
            <div className="col-md-4">
              Colors
              <Tooltip
                title={
                  <div style={{ width: 400 }}>
                    <span>
                      Create color options in Store settings. Colors will then
                      be available for selection when adding products.
                    </span>
                  </div>
                }
                placement="topLeft"
              >
                <span className="border-0">
                  &nbsp;<i className="fa fa-question-circle-o"></i>
                </span>
              </Tooltip>
            </div>
            <div className="col-md-4">
              <div
                style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}
                className="align-items-center"
              >
                <input
                  type="checkbox"
                  checked={this.state.hasColor}
                  onChange={() => {
                    this.setState((state) => {
                      if (!state.hasColor) {
                        return { hasColor: true };
                      } else {
                        return {
                          hasColor: false,
                          showColor: false,
                        };
                      }
                    });
                  }}
                />
                {this.state.hasColor && (
                  <div>
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        this.setState((state) => ({
                          showColor: !state.showColor,
                        }));
                      }}
                    >
                      Select available colors
                    </span>
                  </div>
                )}
              </div>
            </div>
            {this.state.comboValues[0]?.availableColors?.length > 0 && (
              <div className="col-md-4">
                <span
                  style={{ fontSize: "12px" }}
                  onClick={() => {
                    this.setState((state) => ({
                      showSizes: !state.showSizes,
                    }));
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>Selected:</span>{" "}
                  {this.state.comboValues[0]?.availableColors.join(", ")}
                </span>
              </div>
            )}
          </div>

          {this.state.showColor && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-8">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-4"></div>
                  <div className="col-md-8" style={{ paddingLeft: "2rem" }}>
                    <ul style={{ listStyleType: "none" }}>
                      {this.state.allColors.map((color) => (
                        <li style={{ display: "flex", gap: "8px" }}>
                          <input
                            checked={
                              this.state.comboValues[0].availableColors
                                ? this.state.comboValues[0].availableColors.includes(
                                    color
                                  )
                                : false
                            }
                            onChange={() => {
                              let availableColors =
                                this.state.comboValues[0].availableColors;
                              if (Array.isArray(availableColors)) {
                                if (availableColors.includes(color)) {
                                  availableColors.splice(
                                    availableColors.indexOf(color),
                                    1
                                  );
                                } else {
                                  availableColors.push(color);
                                }
                              }
                              let comboValues = this.state.comboValues;
                              comboValues[0].availableColors = availableColors;

                              this.setState({ comboValues });
                            }}
                            type="checkbox"
                          />
                          <label className="ml-1">{color}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div style={{ display: "flex", flexWrap: "wrap" }} className=" mt-2">
            <div className="col-md-4">
              Quantity
              <Tooltip
                placement="topLeft"
                title={() => (
                  <div style={{ width: 400 }}>
                    Leave blank if inventory is unlimited. Product will
                    automatically show ‘Low Quantity (when less than 10 remain)
                    and Sold Out.
                  </div>
                )}
              >
                <span className="border-0">
                  &nbsp;<i className="fa fa-question-circle-o"></i>
                </span>
              </Tooltip>
            </div>
            <div className="col-md-8">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-4"></div>
                <input
                  className="form-control form-control-sm col-3 no-arrow-field"
                  type="number"
                  onChange={(e) => {
                    let value = e.target.value;
                    if (Number(value) >= 0) {
                      if (value.includes(".")) {
                        value = value.slice(0, value.indexOf("."));
                      }
                      let comboValues = this.state.comboValues;
                      comboValues[0].quantity = value;
                      this.setState({
                        comboValues,
                      });
                    }
                  }}
                  value={this.state.comboValues[0].quantity}
                  step={1}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
            <div className="col-md-4">
              Price({groupCurrency})<span style={{ color: "red" }}>*</span>
              <Tooltip
                placement="topLeft"
                title={() => (
                  <div style={{ width: 400 }}>
                    Price displayed in app and registration form. Also displayed
                    on web if ‘non-member’ price is left blank.
                  </div>
                )}
              >
                <span className="border-0">
                  &nbsp;<i className="fa fa-question-circle-o"></i>
                </span>
              </Tooltip>
            </div>
            <div className="col-md-8">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-4"></div>
                <input
                  type="number"
                  className="form-control form-control-sm col-3  no-arrow-field"
                  value={this.state.comboValues[0].price}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (Number(value) >= 0) {
                      if (
                        value.includes(".") &&
                        value.length - value.indexOf(".") > 2
                      ) {
                        value = value.slice(0, value.indexOf(".") + 3);
                      }
                      let comboValues = this.state.comboValues;
                      comboValues[0].price = value;
                      this.setState({
                        comboValues,
                      });
                    }
                  }}
                  step={0.1}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
            <div className="col-md-4">
              Price (non-member) ({groupCurrency})
              <Tooltip
                placement="topLeft"
                title={() => (
                  <div style={{ width: 400 }}>
                    If entered, displayed on web.
                  </div>
                )}
              >
                <span className="border-0">
                  &nbsp;<i className="fa fa-question-circle-o"></i>
                </span>
              </Tooltip>
            </div>
            <div className="col-md-8">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-4"></div>
                <input
                  type="number"
                  className="form-control form-control-sm col-3  no-arrow-field"
                  value={this.state.comboValues[0].nonMemberPrice}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (Number(value) >= 0) {
                      if (
                        value.includes(".") &&
                        value.length - value.indexOf(".") > 2
                      ) {
                        value = value.slice(0, value.indexOf(".") + 3);
                      }

                      let comboValues = this.state.comboValues;
                      comboValues[0].nonMemberPrice = value;
                      this.setState({
                        comboValues,
                      });
                    }
                  }}
                  step={0.1}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
            <div className="col-md-4">
              Sale Price ({groupCurrency})
              <Tooltip
                placement="topLeft"
                title={() => (
                  <div style={{ width: 400 }}>
                    Sale Price displayed in app and registration form. Also
                    displayed on web if ‘non-member’ sale price is left blank.
                  </div>
                )}
              >
                <span className="border-0">
                  &nbsp;<i className="fa fa-question-circle-o"></i>
                </span>
              </Tooltip>
            </div>
            <div className="col-md-8">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-4"></div>
                <input
                  type="number"
                  className="form-control form-control-sm col-3  no-arrow-field"
                  value={this.state.comboValues[0].salePrice}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (Number(value) >= 0) {
                      if (
                        value.includes(".") &&
                        value.length - value.indexOf(".") > 2
                      ) {
                        value = value.slice(0, value.indexOf(".") + 3);
                      }

                      let comboValues = this.state.comboValues;
                      comboValues[0].salePrice = value;
                      this.setState({
                        comboValues,
                      });
                    }
                  }}
                  step={0.1}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
            <div className="col-md-4">
              Sale Price (non-member) ({groupCurrency})
              <Tooltip
                placement="topLeft"
                title={() => (
                  <div style={{ width: 400 }}>
                    If entered, displayed on web.
                  </div>
                )}
              >
                <span className="border-0">
                  &nbsp;<i className="fa fa-question-circle-o"></i>
                </span>
              </Tooltip>
            </div>
            <div className="col-md-8">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-4"></div>
                <input
                  type="number"
                  className="form-control form-control-sm col-3  no-arrow-field"
                  value={this.state.comboValues[0].nonMemberSalePrice}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (Number(value) >= 0) {
                      if (
                        value.includes(".") &&
                        value.length - value.indexOf(".") > 2
                      ) {
                        value = value.slice(0, value.indexOf(".") + 3);
                      }

                      let comboValues = this.state.comboValues;
                      comboValues[0].nonMemberSalePrice = value;
                      this.setState({
                        comboValues,
                      });
                    }
                  }}
                  step={0.1}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
            <div className="col-md-4">
              Shipping Cost ({groupCurrency})
              <Tooltip
                placement="topLeft"
                title={() => (
                  <div style={{ width: 400 }}>
                    Leave blank if shipping not available. Shipping amounts
                    added together for each item at checkout.
                  </div>
                )}
              >
                <span className="border-0">
                  &nbsp;<i className="fa fa-question-circle-o"></i>
                </span>
              </Tooltip>
            </div>
            <div className="col-md-8">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-4"></div>
                <input
                  type="number"
                  className="form-control form-control-sm col-3 no-arrow-field"
                  value={this.state.comboValues[0].shippingCost}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (Number(value) >= 0) {
                      if (
                        value.includes(".") &&
                        value.length - value.indexOf(".") > 2
                      ) {
                        value = value.slice(0, value.indexOf(".") + 3);
                      }

                      let comboValues = this.state.comboValues;
                      comboValues[0].shippingCost = value;
                      this.setState({
                        comboValues,
                      });
                    }
                  }}
                  step={0.1}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
            <div className="col-md-4">
              Unit Cost (Internal) ({groupCurrency})
              <Tooltip
                placement="topLeft"
                title={() => (
                  <div style={{ width: 400 }}>Not displayed publicly.</div>
                )}
              >
                <span className="border-0">
                  &nbsp;<i className="fa fa-question-circle-o"></i>
                </span>
              </Tooltip>
            </div>
            <div className="col-md-8">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-4"></div>
                <input
                  type="number"
                  className="form-control form-control-sm col-3 no-arrow-field"
                  value={this.state.comboValues[0].unitCost}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (Number(value) >= 0) {
                      if (
                        value.includes(".") &&
                        value.length - value.indexOf(".") > 2
                      ) {
                        value = value.slice(0, value.indexOf(".") + 3);
                      }

                      let comboValues = this.state.comboValues;
                      comboValues[0].unitCost = value;
                      this.setState({
                        comboValues,
                      });
                    }
                  }}
                  step={0.1}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    if (this.state.quantityPriceOption === "perCombination") {
      const columns = [
        {
          title: "Size",
          dataIndex: "availableSizes",
          className: "header-white",
          width: 150,
          render: (size, _, index) => (
            <select
              value={size[0]}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].availableSizes = [e.target.value];
                this.setState({ comboValues });
              }}
            >
              <option value={""} key="select">
                --Select size--
              </option>
              {allTshirtSizes.map((sizeOption) => {
                return (
                  <option value={sizeOption} key={sizeOption}>
                    {sizeOption}
                  </option>
                );
              })}
            </select>
          ),
        },
        {
          title: (
            <div>
              Color
              <Tooltip
                title={
                  <div style={{ width: 400 }}>
                    <span>
                      Create color options in Store settings. Colors will then
                      be available for selection when adding products.
                    </span>
                  </div>
                }
                placement="topLeft"
              >
                <span className="border-0">
                  &nbsp;<i className="fa fa-question-circle-o"></i>
                </span>
              </Tooltip>
            </div>
          ),
          dataIndex: "availableColors",
          className: "header-white",
          width: 150,
          render: (color, _, index) => (
            <select
              value={color[0]}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].availableColors = [e.target.value];
                comboValues[index].availableColors = comboValues[
                  index
                ].availableColors.filter((color) => color);
                this.setState({ comboValues });
              }}
            >
              <option value={""} key="select">
                --Select color--
              </option>
              {this.state.allColors.map((colorOption) => {
                return (
                  <option value={colorOption} key={colorOption}>
                    {colorOption}
                  </option>
                );
              })}
            </select>
          ),
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          className: "header-white",
          width: 70,
          render: (quantity, _, index) => (
            <input
              type="number"
              value={quantity}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].quantity = e.target.value;
                this.setState({ comboValues });
              }}
              style={{
                borderRadius: 3,
                width: "100%",
                border: "1px solid #ced4da",
              }}
            />
          ),
        },
        {
          title: "Sold",
          dataIndex: "sold",
          className: "header-white",
          width: 70,
          render: (quantity, _, index) => (
            <input
              type="number"
              disabled={true}
              value={quantity}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].quantity = e.target.value;
                this.setState({ comboValues });
              }}
              style={{
                borderRadius: 3,
                width: "100%",
                border: "1px solid #ced4da",
              }}
            />
          ),
        },
        {
          title: "Remaining",
          className: "header-white",
          width: 70,
          render: (record, _, index) => (
            <input
              type="number"
              disabled={true}
              value={(record.quantity || 0) - (record.sold || 0)}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].quantity = e.target.value;
                this.setState({ comboValues });
              }}
              style={{
                borderRadius: 3,
                width: "100%",
                border: "1px solid #ced4da",
              }}
            />
          ),
        },
        {
          title: (
            <div>
              Price ({groupCurrency})<span style={{ color: "red" }}>*</span>
            </div>
          ),
          dataIndex: "price",
          className: "header-white",
          width: 100,
          render: (price, _, index) => (
            <input
              type="number"
              value={price}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].price = e.target.value;
                this.setState({ comboValues });
              }}
              style={{
                borderRadius: 3,
                width: "100%",
                border: "1px solid #ced4da",
              }}
            />
          ),
        },
        {
          title: <div>Sale Price ({groupCurrency})</div>,
          dataIndex: "salePrice",
          className: "header-white",
          width: 100,
          render: (salePrice, _, index) => (
            <input
              type="number"
              value={salePrice}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].salePrice = e.target.value;
                this.setState({ comboValues });
              }}
              style={{
                borderRadius: 3,
                width: "100%",
                border: "1px solid #ced4da",
              }}
            />
          ),
        },
        {
          title: <div>Non Member ({groupCurrency})</div>,
          dataIndex: "nonMemberPrice",
          className: "header-white",
          width: 100,
          render: (nonMemberPrice, _, index) => (
            <input
              type="number"
              value={nonMemberPrice}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].nonMemberPrice = e.target.value;
                this.setState({ comboValues });
              }}
              style={{
                borderRadius: 3,
                width: "100%",
                border: "1px solid #ced4da",
              }}
            />
          ),
        },
        {
          title: <div>Sale Price (non-member) ({groupCurrency})</div>,
          dataIndex: "nonMemberSalePrice",
          className: "header-white",
          width: 100,
          render: (salePrice, _, index) => (
            <input
              type="number"
              value={salePrice}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].nonMemberSalePrice = e.target.value;
                this.setState({ comboValues });
              }}
              style={{
                borderRadius: 3,
                width: "100%",
                border: "1px solid #ced4da",
              }}
            />
          ),
        },
        {
          title: <div>Shipping Cost ({groupCurrency})</div>,
          dataIndex: "shippingCost",
          className: "header-white",
          width: 100,
          render: (shippingCost, _, index) => (
            <input
              type="number"
              value={shippingCost}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].shippingCost = e.target.value;
                this.setState({ comboValues });
              }}
              style={{
                borderRadius: 3,
                width: "100%",
                border: "1px solid #ced4da",
              }}
            />
          ),
        },
        {
          title: (
            <div>
              <p style={{ margin: 0 }}>Unit Cost</p>
              <p>(Internal) ({groupCurrency})</p>
            </div>
          ),
          dataIndex: "unitCost",
          width: 100,
          className: "header-white",
          render: (unitCost, _, index) => (
            <input
              type="number"
              value={unitCost}
              onChange={(e) => {
                let comboValues = this.state.comboValues;
                comboValues[index].unitCost = e.target.value;
                this.setState({ comboValues });
              }}
              style={{
                borderRadius: 3,
                width: "100%",
                border: "1px solid #ced4da",
              }}
            />
          ),
        },
      ];

      return (
        <>
          <p>Create Price, Quantity, Size and Color combinations.</p>
          <Table
            dataSource={this.state.comboValues}
            columns={columns}
            pagination={false}
            bordered
          />
          <span
            style={{
              color: "#3480eb",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              let comboValues = this.state.comboValues;
              let object = JSON.parse(JSON.stringify(comboValueTemplate));
              // if (this.state.allColors && Array.isArray(this.state.allColors)) {
              //     object.availableColors = [this.state.allColors[0]];
              // }
              // object.availableSizes = [allTshirtSizes[0]];
              comboValues.push(object);
              this.setState({ comboValues });
            }}
          >
            + Add Row
          </span>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  return {
    user: state.user,
    currentGroup,
    adminData: state.adminData.allGroups[currentGroup],
  };
};

export default connect(mapStateToProps)(withRouter(CreateItem));
