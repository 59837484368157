import React from "react";
import { Button } from "antd";
import "./ChantButton.css";

const ChantButton = ({ className, type = "primary", ...props }) => {
  return (
    <Button
      className={`ant-themed-button ${className || ""}`}
      type={type}
      {...props}
    />
  );
};

export default ChantButton;
