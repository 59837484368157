import { store } from "../..";
import { applicationError } from "../../redux/actions/error";
import { openTransaction } from "./connection";

export function addContentsToLocal(contentsList, tab, groupId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    const contents = openTransaction(
      indexDb,
      `${tab}Contents-${groupId}`,
      `${tab}Contents-${groupId}`,
      "readwrite"
    );
    if (!contents) return;
    contentsList.forEach((content, index) => {
      const addRequest = contents.add(content);
      addRequest.onsuccess = function (event) {
        if (index === contentsList.length - 1) {
          resolve();
        }
      };
      addRequest.onerror = function (event) {};
    });
  });
}

export function clearContentFromLocal(tab, groupId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    const contents = openTransaction(
      indexDb,
      `${tab}Contents-${groupId}`,
      `${tab}Contents-${groupId}`,
      "readwrite"
    );
    if (!contents) return;

    const clearRequest = contents.clear();
    clearRequest.onsuccess = function (event) {
      resolve();
    };
  });
}

export function editContentInLocal(content, tab, groupId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    const contents = openTransaction(
      indexDb,
      `${tab}Contents-${groupId}`,
      `${tab}Contents-${groupId}`,
      "readwrite"
    );
    if (!contents) return;

    const cursorRequest = contents.openCursor(content.id);
    cursorRequest.onsuccess = function (event) {
      let cursor = cursorRequest.result;
      if (cursor) {
        let data = cursor.value;
        cursor.update({ ...data, ...content });
      }
    };
  });
}

export function getContentsFromLocal(
  skip,
  limit,
  tab,
  groupId,
  orderBy,
  order
) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    let contents = openTransaction(
      indexDb,
      `${tab}Contents-${groupId}`,
      `${tab}Contents-${groupId}`
    );
    if (!contents) return;

    let getRequest;
    if (orderBy) {
      contents = contents.index(orderBy);
      getRequest = contents.openCursor(null, order);
    } else {
      // contents = contents.index('pageId')
      // getRequest = contents.openCursor()
      contents = contents.index("details.eventStartDate");
      getRequest = contents.openCursor(
        null,
        tab === "current" ? "next" : "prev"
      );
    }

    let skiped = false,
      index = 0;
    const result = [];
    getRequest.onsuccess = function (event) {
      let cursor = getRequest.result;
      if (cursor) {
        if (!skiped && skip) {
          cursor.advance(skip);
          skiped = true;
        } else {
          result.push(cursor.value);
          cursor.continue();
        }
      } else {
        resolve(result);
        result.forEach((data) => {});
      }
    };

    getRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message: "Error fetching contents from local db",
          errorCode: "indexDb",
        })
      );
    };
  });
}

export function deleteContentFromLocal(tab, groupId, contentId) {
  return new Promise((resolve, reject) => {
    const indexDb = store.getState().adminData.connection;
    const contents = openTransaction(
      indexDb,
      `${tab}Contents-${groupId}`,
      `${tab}Contents-${groupId}`,
      "readwrite"
    );
    if (!contents) return;

    const deleteRequest = contents.delete(contentId);
    deleteRequest.onsuccess = function (event) {
      // store.dispatch(applicationError({ err: {}, message: "Error updating contents in local db, refresh list to see the changes", errorCode: 'indexDb-modal' }))
      resolve();
    };

    deleteRequest.onerror = function (event) {
      store.dispatch(
        applicationError({
          err: {},
          message:
            "Error updating contents in local db, refresh list to see the changes",
          errorCode: "indexDb-modal",
        })
      );
    };
  });
}
