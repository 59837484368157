import React from "react";
import ResponsiveMobileTab from "../../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";
import { useLocation, useNavigate } from "react-router-dom";
import Rewards from "./Rewards";
import Events from "./Events/Events";

const Leaderboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentTab = () => {
    if (location.pathname.includes("/rewards")) {
      return "rewards";
    }
    return "points";
  };

  const menuItems = [
    {
      key: "points",
      label: "Points",
    },
    {
      key: "rewards",
      label: "Rewards",
    },
  ];

  const handleTabChange = (e) => {
    const tabKey = e.target.name;
    switch (tabKey) {
      case "points":
        navigate("/admin/content/leaderboard");
        break;
      case "rewards":
        navigate("/admin/content/leaderboard/rewards");
        break;
      default:
        navigate("/admin/content/leaderboard");
    }
  };

  const renderContent = () => {
    if (location.pathname.includes("/rewards")) {
      return <Rewards />;
    }
    return <Events />;
  };

  return (
    <div className="leaderboard-container">
      <nav className="leaderboard-nav">
        <ul
          style={{
            listStyle: "none",
            padding: "1rem 1rem 0rem",
            margin: 0,
            display: "flex",
          }}
        >
          <ResponsiveMobileTab
            menuItems={menuItems}
            param={getCurrentTab()}
            changeTab={handleTabChange}
            primaryColor="var(--primary-background)"
            tabIndex={0}
          />
        </ul>
      </nav>

      <div className="leaderboard-content" style={{ marginTop: "24px" }}>
        {renderContent()}
      </div>
    </div>
  );
};

export default Leaderboard;
