import { Tooltip } from "antd";
import React from "react";

const HelpTooltip = ({ text, color = "black" }) => {
  return (
    <Tooltip title={<div style={{ width: 400 }}>{text}</div>}>
      <div
        style={{
          border: `1px solid ${color}`,
          borderRadius: "50%",
          width: "18px",
          height: "18px",
        }}
        className="flex-center"
      >
        <span style={{ color }}>?</span>
      </div>
    </Tooltip>
  );
};

export default HelpTooltip;
