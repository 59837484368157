import { collection, getDocs } from "firebase/firestore";
import { fbGetMembers } from "../firebaseService/endPoints/admin/members";
import { db } from "../firebaseService/connection";
import useGlobalStore from "../../store/store";

export const fetchMembers = async (groupId) => {
  const { setMembersList, setMembersLoading } =
    useGlobalStore.getState().members;

  setMembersLoading(true); // Set loading to true immediately

  fbGetMembers(groupId)
    .then(async (members) => {
      // Map members data
      members = members.map((contents, index) => {
        return {
          ...contents,
          pageId: index,
          joinedChant: "Yes",
          name:
            contents.name ||
            contents.fullName ||
            `${contents.firstName} ${contents.lastName}`,
          lockStatus: contents.lockStatus || false,
          address: contents.address1 || contents.address,
          fullName:
            contents.fullName ||
            contents.name ||
            `${contents.firstName} ${contents.lastName}`,
          tshirtSize: contents.tshirtSize || contents["t-shirtSize"],
          registrationDate:
            contents.registeredOn ||
            contents.registrationDate ||
            contents.registeredOnChantDate,
        };
      });

      // Fetch unregistered users
      let unregisteredUsers = await getDocs(
        collection(db, "invite_emails", groupId, "userDetails")
      ).then((snap) => {
        let members = [];
        let emailKeyMap = {};
        let userDetails = {};

        for (let doc of snap.docs) {
          if (doc.exists()) {
            let data = doc.data() || {};
            if (!doc.userId) {
              userDetails[doc.id] = data;

              let email = (data.email ?? "").toLowerCase();
              if (!emailKeyMap[email]) {
                emailKeyMap[email] = doc.id;
              } else {
                if (
                  emailKeyMap[email] === email &&
                  doc.id.toLowerCase() !== email
                ) {
                  emailKeyMap[email] = doc.id;
                }
              }
            }
          }
        }
        members = Object.values(emailKeyMap).map((key, index) => {
          let userDetail = userDetails[key];
          return {
            ...userDetail,
            userRole: userDetail?.userRole,
            id: -1 * (index + 1),
            name:
              userDetail.name ||
              userDetail.fullName ||
              `${userDetail.firstName} ${userDetail.lastName}`,
            lockStatus: userDetail.lockStatus || false,
            registrationDate: userDetail.registeredOn,
            address: userDetail.address || userDetail.address1,
            registeredOnChantDate: "",
            fullName:
              userDetail.fullName ||
              userDetail.name ||
              `${userDetail.firstName} ${userDetail.lastName}`,
            tshirtSize: userDetail.tshirtSize || userDetail["t-shirtSize"],
          };
        });
        return members;
      });

      // Combine members and unregistered users
      let membersEmail = members.map((member) => member.email);
      unregisteredUsers.forEach((user) => {
        if (!membersEmail.includes(user.email)) {
          members.push({
            ...user,
            pageId: members.length,
            joinedChant: "No",
          });
        }
      });

      // Clean up members
      members.forEach((member) => {
        if (member.children && member.children.length === 0) {
          delete member.children;
        }
      });

      // Set the members list
      setMembersList(groupId, members);
    })
    .catch((error) => {
      console.error("Error fetching members:", error); // Log error for easier debugging
    })
    .finally(() => {
      setMembersLoading(false); // Always set loading to false
    });
};
