import Axios from "axios";
import { continents, countries } from "countries-list";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import moment from "moment";
import { postUpdateCDNUtil } from "../../../../helperFunctions/util";
import { store } from "../../../../index";
import { applicationError } from "../../../../redux/actions/error";
import logger from "../../../../utils/logger";
import { db, storage } from "../../connection";
import { getGroups } from "../clubAdmin/groups";
import { uploadImage } from "./website";

const imagePath = "content/images/";

/* ------- CREATE -------- */

// Create a new content for a group
export function fbCreateContent(data, imageFile) {
  return new Promise(async (resolve, reject) => {
    // default's intialization
    let downloadUrl = "";

    data.rsvp = {
      attending: 0,
      maybe: 0,
      no: 0,
    };
    data.createdOn = parseInt(Date.now() / 1000);
    const batch = writeBatch(db);
    const adminPostDoc = doc(
      collection(db, "groupAdminPost", data.groupId, "posts")
    );

    if (imageFile) {
      // upload content image first
      try {
        downloadUrl = await fbUploadContentImage(
          imageFile,
          adminPostDoc.id,
          data.groupId
        );
      } catch (err) {
        let message =
          "There was an error uploading image for the content, could not complete the operation, contact admin";
        store.dispatch(
          applicationError({
            message,
            intensity: "mid",
            err,
            errorCode: "firebase",
          })
        );
        return;
      }
    }

    if (data.chatChannel.channelName) {
      // create chat channel for the content
      const chatChannelDoc = doc(
        db,
        "chatChannel",
        data.groupId,
        "channels",
        adminPostDoc.id
      );

      const chatChannelData = populateChatChannel(
        data.chatChannel,
        downloadUrl
      );
      batch.set(chatChannelDoc, { image: "", ...chatChannelData });
    }

    data.details.image = downloadUrl;
    data.chatChannel = data.chatChannel.channelName;

    batch.set(adminPostDoc, data);

    batch
      .commit()
      .then(() => {
        resolve({ ...data, id: adminPostDoc.id });
      })
      .catch((err) => {
        let message = "There was error creating content, contact admin";
        store.dispatch(
          applicationError({
            message,
            intensity: "high",
            err,
            errorCode: "firebase",
          })
        );
      });
  });
}

// Create chat channel, called by create content
function populateChatChannel(data, image) {
  // 3 fields sent by content model, channelName, startDate, endDate
  data = { ...data };
  data.broadcast = false;
  if (image) data.image = image;
  data.description = "";
  data.channelType = "public";
  data.membersIds = [];
  return data;
}

// Create image for content, called by create content
export function fbUploadContentImage(file, id, groupId) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    const folderRef = ref(storageRef, `${groupId}/${imagePath}${id}`);
    // storageRef.child(`${imagePath}${id}`);

    uploadBytes(folderRef, file)
      .then(async (snapshot) => {
        const downloadUrl = await getDownloadURL(snapshot.ref);
        resolve(downloadUrl, snapshot.metadata.fullPath);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fbCheckChatChannelName(groupId, channelName, adminPostId) {
  return new Promise((resolve, reject) => {
    let collectionRef = collection(db, "chatChannel", groupId, "channels");
    const q = query(collectionRef, where("channelName", "==", channelName));
    getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          if (adminPostId && querySnapshot.docs[0].id === adminPostId) {
            resolve();
          } else reject();
        } else {
          resolve();
        }
      })
      .catch((err) => {
        store.dispatch(
          applicationError({
            message: "Error fetching chat-channel details",
            intensity: "high",
            err,
            errorCode: "firebase",
          })
        );
      });
  });
}

// Read all the contents
export function fbGetContents(groupId, tab) {
  // let rsvps = []

  let date = new Date();
  date.setHours(0, 0, 0, 0);

  return new Promise(async (resolve, reject) => {
    let result = [];

    let q = query(
      collection(db, "groupAdminPost", groupId, "posts"),
      where(
        "details.eventStartDate",
        `${tab === "current" ? ">=" : "<"}`,
        parseInt(date.getTime() / 1000)
      ),
      orderBy("details.eventStartDate", `${tab === "current" ? "asc" : "desc"}`)
    );

    await getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data() || {};
          data.id = doc.id;
          result.push(data);
        });
      })
      .catch((err) => {
        let message = "There was an error fetching contents for the group";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      });

    //parent polls
    let { parents, clubId } = await getDoc(doc(db, "group", groupId)).then(
      (doc) => doc.data() || {}
    );
    if (parents && Array.isArray(parents)) {
      await getDocs(
        query(
          collection(db, "groupAdminPost", `${clubId}_club_contents`, "posts"),
          where(
            "details.eventStartDate",
            `${tab === "current" ? ">=" : "<"}`,
            parseInt(date.getTime() / 1000)
          ),
          orderBy(
            "details.eventStartDate",
            `${tab === "current" ? "asc" : "desc"}`
          )
        )
      )
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data() || {};
            data.id = doc.id;
            if (parents.includes(data.type)) {
              result.push(data);
            }
          });
        })
        .catch((err) => {
          let message = "There was an error fetching contents for the group";
          store.dispatch(
            applicationError({
              message,
              intensity: "low",
              err,
              errorCode: "firebase",
            })
          );
          reject({ message });
        });
    }
    resolve(result);
  });
}

// Read a single content based on id
export function fbGetContent(id, groupId) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "groupAdminPost", groupId, "posts", id))
      .then((querySnapshot) => {
        resolve(querySnapshot.data());
      })
      .catch((err) => {
        let message = "There was an error fetching the document";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      });
  });
}

export function fbGetChatChannel(groupId, adminPostId) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "chatChannel", groupId, "channels", adminPostId))
      .then((querySnapshot) => {
        if (querySnapshot.exists()) {
          let data = querySnapshot.data() || {};
          resolve(data);
        } else {
          resolve();
        }
      })
      .catch((err) => {
        store.dispatch(
          applicationError({
            message: "Error fetching chat-channel details",
            intensity: "high",
            err,
            errorCode: "firebase",
          })
        );
      });
  });
}

/* ------- UPDATE -------- */

// Update contents
export function fbUpdateContent(
  data,
  adminPostId,
  imageFile,
  channelChange,
  newChannel,
  deleteImage
) {
  return new Promise(async (resolve, reject) => {
    const batch = writeBatch(db);

    const adminPostDoc = doc(
      db,
      "groupAdminPost",
      data.groupId,
      "posts",
      adminPostId
    );

    let chatChannelImage;

    if (deleteImage) {
      try {
        await fbDeleteImage(adminPostId, data.groupId);
      } catch (err) {
        // let message =
        //   "There was an error deleting the content image, could not complete the operation, contact admin";
        // store.dispatch(
        //   applicationError({
        //     message,
        //     intensity: "high",
        //     err,
        //     errorCode: "firebase"
        //   })
        // );
        // reject({ message });
        // return;
      }
      data.details.image = "";
      data.chatChannel.image = "";
    }
    if (imageFile) {
      try {
        const downloadUrl = await fbUploadContentImage(
          imageFile,
          adminPostId,
          data.groupId
        );
        data.details.image = downloadUrl;
        chatChannelImage = downloadUrl;
        data.chatChannel.image = chatChannelImage;
      } catch (err) {
        let message =
          "There was an error uploading the image, could not complete the operation, contact admin";
        store.dispatch(
          applicationError({
            message,
            intensity: "mid",
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      }
    }

    const chatChannelDoc = doc(
      db,
      "chatChannel",
      data.groupId,
      "channels",
      adminPostId
    );

    if (channelChange) {
      // if channelName or dates changed, update the channel
      batch.set(chatChannelDoc, { ...data.chatChannel }, { merge: true });
      data.chatChannel = data.chatChannel.channelName;
    } else if (newChannel) {
      chatChannelImage = chatChannelImage || data.details.image;
      const chatChannelData = populateChatChannel(
        data.chatChannel,
        chatChannelImage
      );
      data.chatChannel = data.chatChannel.channelName;
      batch.set(
        chatChannelDoc,
        { image: "", ...chatChannelData },
        { merge: true }
      );
    } else if (chatChannelImage && data.chatChannel.channelName) {
      batch.set(chatChannelDoc, { image: chatChannelImage }, { merge: true });
      // delete data.chatChannel
    } else if (deleteImage) {
      batch.set(chatChannelDoc, { image: "" }, { merge: true });
      // delete data.chatChannel;
    } else {
      // delete data.chatChannel;
    }

    if (typeof data.chatChannel !== "string") {
      delete data.chatChannel;
    }

    delete data.groupId;

    if (
      Array.isArray(data.additionalLocations) &&
      data.additionalLocations.length > 0
    ) {
      data.mapsData = deleteField();
    }

    batch.update(adminPostDoc, data);
    batch
      .commit()
      .then(() => {
        resolve({ ...data, id: adminPostDoc.id });
      })
      .catch((err) => {
        let message =
          "There was an error updating the content, could not complete the operation, contact admin";
        store.dispatch(
          applicationError({
            message,
            intensity: "mid",
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      });
  });
}

/* ------- DELETE -------- */

// Delete content based on id
export function fbDeleteContent(id, deleteImage, groupId) {
  return new Promise(async (resolve, reject) => {
    getDocs(query(collection(db, "groupAdminPost", id, "rsvp"), limit(1))).then(
      async (querySnapshot) => {
        if (querySnapshot.docs && querySnapshot.docs.length > 0) {
          reject({
            message: "This content has rsvps, cant delete the content",
          });
          return;
        }

        if (deleteImage) {
          try {
            await fbDeleteImage(id, groupId);
          } catch (err) {
            // let message =
            //   "There was an error deleting the content image, could not complete the operation, contact admin";
            // store.dispatch(
            //   applicationError({
            //     message,
            //     intensity: "high",
            //     err,
            //     errorCode: "firebase"
            //   })
            // );
            // reject({ message });
            // return;
          }
        }
        const docRef = doc(db, "groupAdminPost", groupId, "posts", id);
        const batch = writeBatch(db);

        batch.delete(docRef);
        batch
          .commit()
          .then(() => {
            postUpdateCDNUtil(groupId, "event");
            resolve();
          })
          .catch((err) => {
            let message =
              "There was an error deleting the content completely, could not complete the operation, contact admin";
            store.dispatch(
              applicationError({
                message,
                intensity: "high",
                err,
                errorCode: "firebase",
              })
            );
            reject({ message });
          });
      }
    );
  });
}

// Delete image for content, called by delete content
function fbDeleteImage(id, groupId) {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    const imageRef = ref(storageRef, `${groupId}/content/images/${id}`);
    deleteObject(imageRef).then(resolve).catch(reject);
  });
}

export function fbUpdateNotification(data, oldNoti, gId, ctnId) {
  let countrtyNameCodeMap = {};
  Object.keys(countries).forEach((key) => {
    countrtyNameCodeMap[key] = countries[key].name;
  });
  return new Promise(async (resolve, reject) => {
    const batch = writeBatch(db);
    if (data.notifications !== null && oldNoti !== null) {
      var aList = [];
      data.notifications.forEach((f) => {
        var ind = oldNoti.indexOf(f);
        if (ind === -1) aList.push(f);
        else oldNoti.splice(ind, 1);
      });
      var dList = oldNoti.filter((w) => !aList.includes(w));
    }

    const ref = doc(db, "contentNotifications", gId);

    if (dList.length !== 0 || aList.length !== 0) {
      var snap = await getDoc(ref);
      // let notifications={}
      var ts = [],
        ns = [],
        tys = [],
        eDate = [],
        mList = [],
        ticketIds = [],
        volunteerOption = [];
      if (snap.exists()) {
        ts = snap.data().time || [];
        ns = snap.data().eventName || [];
        tys = snap.data().type || [];
        eDate = snap.data().epoch || [];
        mList = snap.data().matches || [];
        ticketIds =
          snap.data().ticketIds ||
          Array.from({ length: (ts || []).length }, (_) => "");
        volunteerOption =
          snap.data().volunteerOption ||
          Array.from({ length: (ts || []).length }, (_) => "");
        // notifications=snap.data().notifications || {};

        dList.forEach((f) => {
          var i = ts.indexOf(f);
          if (i !== -1) {
            ts.splice(i, 1);
            ns.splice(i, 1);
            tys.splice(i, 1);
            eDate.splice(i, 1);
            mList.splice(i, 1);
          }
        });

        // dList.forEach((f) => {
        //   let deleteKey=''

        //   for(let key in notifications){
        //     if(notifications[key].timestamp===f){
        //       deleteKey=key;
        //       break;
        //     }
        //   }

        //   delete notifications[deleteKey]
        // });
      }

      for (var j = 0; j < aList.length; j++) {
        var today = _isToday(aList[j]);
        var mt = data.matchName.trim().split("(")[0].trim();
        let eventDate = moment
          .unix(data.details.eventStartDate)
          .set({ h: 0, m: 0, seconds: 59 })
          .unix();

        if (today) {
          if (gId.includes("contents")) {
            getGroups().then((groups) => {
              let groupIds = [];
              let clubGroup = groups.find((group) => group.isParent);
              let clubGroupId = clubGroup.id;
              for (var i = 0; i < data.groups.length; i++) {
                let param = data.groups[i];
                if (param === "global") {
                  groupIds = [...groups.map((group) => group.id)];
                }
                groupIds = [
                  ...groupIds,
                  ...groups
                    .filter(
                      (group) =>
                        (group.id === param ||
                          continents[group.supportersRegion] === param ||
                          countries[group.supportersCountry].name === param) &&
                        !groupIds.includes(group.id)
                    )
                    .map((group) => group.id),
                ];
              }
              groupIds.forEach((gId) => {
                _sendNotification(
                  data.title,
                  data.type,
                  data.volunteerRequests,
                  data.ticketId,
                  eventDate,
                  mt,
                  gId,
                  clubGroupId,
                  ctnId,
                  data.showForFans
                );
              });
            });
          } else {
            _sendNotification(
              data.title,
              data.type,
              data.volunteerRequests,
              data.ticketId,
              eventDate,
              mt,
              gId,
              null,
              ctnId,
              data.showForFans
            );
          }
        } else {
          // let notification={
          //   timestamp: aList[j],
          //   eventName: data.title !== "" ? data.title : "-",
          //   type: data.type,
          //   epoch: eventDate,
          //   ticketId: data.ticketId,
          //   volunteerOption: Object.keys(data.volunteerRequests || {}).length > 0
          // }
          // notifications[generateId(8)]=notification

          ts.push(aList[j]);
          ns.push(data.title !== "" ? data.title : "-");
          tys.push(data.type);
          eDate.push(eventDate);
          mList.push(mt);
          ticketIds.push(data.ticketId);
          volunteerOption.push(
            Object.keys(data.volunteerRequests || {}).length > 0
          );
        }
      }
      batch.set(ref, {
        eventName: ns,
        time: ts,
        type: tys,
        epoch: eDate,
        matches: mList,
        ticketIds: ticketIds,
        volunteerOption: volunteerOption,
        // 'notifications': notifications
      });

      batch
        .commit()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          let message =
            "There was an error updating the notification content, could not complete the operation, contact admin";
          store.dispatch(
            applicationError({
              message,
              intensity: "mid",
              err,
              errorCode: "firebase",
            })
          );
          reject({ message });
        });
    }
  });
}

function buildNotificationBody(
  tit,
  type,
  match,
  eventEpoch,
  volunteerRequests,
  ticketId
) {
  if (type.toLowerCase() === "news" || type.toLowerCase() === "update") {
    return `${tit || type}${match ? ` for ${match}` : ""}. Read More.`;
  }

  let body = `${tit || type}${match ? ` for ${match}` : ""} is scheduled on ${_getDate(eventEpoch)}.`;

  let action = "";
  if (
    volunteerRequests &&
    Object.keys(volunteerRequests).length > 0 &&
    !ticketId
  ) {
    action = "Volunteer and RSVP.";
  } else if (ticketId) {
    action = "Buy Tickets and RSVP.";
  } else {
    action = "RSVP.";
  }

  return `${body} ${action}`.trim();
}

export async function _sendNotification(
  tit,
  type,
  volunteerRequests,
  ticketId,
  eventEpoch,
  match,
  gId,
  clubGroupId,
  ctnId,
  sendFanNotification
) {
  var data = await fbGetGroupDetails(gId);
  var clubGroupData;
  if (clubGroupId) {
    clubGroupData = await fbGetGroupDetails(clubGroupId);
  }

  const title =
    type.toLowerCase() === "volunteer"
      ? `Volunteer with ${data.groupName}`
      : `${clubGroupData ? clubGroupData.groupName : data.groupName} ${type}`;

  const bodyString = buildNotificationBody(
    tit,
    type,
    match,
    eventEpoch,
    volunteerRequests,
    ticketId
  );

  var payload = {
    gId: gId,
    image: "",
    gName: data.groupName,
    gCId: data.clubId,
    gLId: data.leagueId,
    timestamp: Date.now(),
    authorName: "",
    authorDocId: "",
    senderName: "",
    senderDocId: "",
    matchId: "",
    cName: "",
    cType: "",
    cId: "",
    pId: "",
    tit: title,
    bdy: bodyString,
    receiverDocId: "",
    topic: `${gId}_eventUpdates`,
    ctnId: ctnId,
    sendFanNotification: sendFanNotification || false,
    type: "admin_post",
  };

  Axios.post(
    "https://us-central1-chant2019.cloudfunctions.net/sendBulkNotification",
    JSON.stringify(payload),
    {
      headers: {
        "content-type": "application/json",
      },
    }
  ).catch((err) => {
    logger.error("Error ", err);
  });
}

export async function sendBareNotification(
  tit,
  body,
  gId,
  clubGroupId,
  sendFanNotification,
  isWelcomeCard,
  sendMemberNotification
) {
  var groupData = await fbGetGroupDetails(gId);
  var topic = `${gId}_eventUpdates`;
  var data = {
    gId: gId,
    image: "",
    gName: groupData.groupName,
    gCId: groupData.clubId,
    gLId: groupData.leagueId,
    timestamp: "",
    authorName: "",
    authorDocId: "",
    senderName: "",
    senderDocId: "",
    matchId: "",
    cName: "",
    cType: "",
    cId: "",
    pId: "",
    tit: tit,
    bdy: body,
    type: "admin_post",
    receiverDocId: "",
    topic: topic,
    ctnId: "",
    sendFanNotification: sendFanNotification,
    sendMemberNotification: sendMemberNotification,
    isWelcomeCard: isWelcomeCard,
  };
  if (sendFanNotification) {
    topic = `${gId}_general`;
    data.topic = topic;
  }
  Axios.post(
    "https://us-central1-chant2019.cloudfunctions.net/sendBulkNotification",
    JSON.stringify(data),
    {
      headers: {
        "content-type": "application/json",
      },
    }
  ).catch((err) => {
    logger.error("Error ", err);
  });
}

export function _isToday(timestamp) {
  var date = new Date(timestamp * 1000);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  var today = new Date();
  var y = today.getFullYear();
  var m = today.getMonth() + 1;
  var d = today.getDate();
  if (y === year && d === day && m === month) {
    return true;
  } else {
    return false;
  }
}

export function _getDate(timestamp) {
  var date = new Date(timestamp * 1000);
  var d = date.getDate();
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var week = days[date.getDay()];
  var month = monthNames[date.getMonth()];
  var res = `${week} ${month} ${d}`;
  return res;
}

export function fbGetGroupDetails(groupId) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "group", groupId))
      .then((querySnapshot) => {
        resolve(querySnapshot.data());
      })
      .catch((err) => {
        let message = "There was an error fetching the group details";
        reject({ message });
      });
  });
}

export function getLocations(groupId) {
  return new Promise((resolve, reject) => {
    let locations = [];
    getDocs(collection(db, "group", groupId, "locations"))
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          let data = doc.data() || {};
          data.id = doc.id;

          locations.push(data);
        });
        resolve(locations);
      })
      .catch((err) => {
        let message = "There was an error fetching the locations";
        reject({ message });
      });
  });
}

export function getLocationDetails(groupId, docId) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "group", groupId, "locations", docId))
      .then((doc) => {
        let data = {};
        if (doc.exists()) {
          data = doc.data() || {};
          data.id = doc.id;
        }
        resolve(data);
      })
      .catch((err) => {
        let message = "There was an error fetching the locations";
        reject({ message });
      });
  });
}

export function fbSaveLocationDetails(data, groupId, docId) {
  return new Promise(async (res, rej) => {
    let docRef = doc(collection(db, "group", groupId, "locations"));
    if (docId) {
      docRef = doc(db, "group", groupId, "locations", docId);
    }

    if (data.locationImage && typeof data.locationImage === "object") {
      let downloadUrl = await uploadImage(
        data.locationImage,
        `${groupId}/locations/${docRef.id}/locationImage`,
        0
      ).then(({ downloadUrl }) => {
        return downloadUrl;
      });
      data.locationImage = downloadUrl;
    }

    setDoc(docRef, data, {
      merge: true,
    })
      .then(() => {
        updateLocationInSections(data, groupId, docId).then(() => res());
        res();
      })
      .catch((e) => {
        rej(e);
      });
  });
}

const updateLocationInSections = async (data, groupId, id) => {
  try {
    const websiteDocRef = doc(db, "website", groupId);

    const docSnap = await getDoc(websiteDocRef);

    if (!docSnap.exists()) {
      return;
    }

    const docData = docSnap.data();
    const sections = docData.sections || [];

    const eventLocationId = sections.eventLocations.findIndex(
      (item) => item.id === id
    );
    const selectedLocationId = sections.selectedLocations.findIndex(
      (item) => item.id === id
    );

    const updatedSections = { ...sections };

    if (eventLocationId !== -1) {
      updatedSections.eventLocations = updatedSections.eventLocations.map(
        (location) => (location.id === id ? { ...location, ...data } : location)
      );
    }

    if (selectedLocationId !== -1) {
      updatedSections.selectedLocations = updatedSections.selectedLocations.map(
        (location) => (location.id === id ? { ...location, ...data } : location)
      );
    }

    await updateDoc(websiteDocRef, {
      sections: updatedSections,
    });

    postUpdateCDNUtil(groupId);
  } catch (error) {
    logger.error("Error updating locations: ", error);
  }
};

export function fbDeleteLocation(groupId, docId) {
  let docRef = doc(db, "group", groupId, "locations", docId);
  return new Promise((res, rej) => {
    deleteDoc(docRef).then(res).catch(rej);
  });
}

export function saveWelcomeCardDetails(data, groupId) {
  let docRef = doc(db, "group", groupId);
  return new Promise(async (res, rej) => {
    let index = 0;
    for (let key of Object.keys(data)) {
      let value = data[key];
      value.details = draftToHtml(
        convertToRaw(value.details.getCurrentContent())
      );
      let image = value.image;
      if (image) {
        if (typeof image === "object") {
          await uploadImage(image, `${groupId}/welcomeCard/images/${index}`, 0);
        }
        value.image = true;
      } else {
        value.image = false;
      }
      if (value.button.url && !`${value.button.url}`.startsWith("https://")) {
        value.button.url = `https://${value.button.url}`;
      }
      value.welcomeCardRepublishTimestamp = moment().unix();
      index++;
    }
    let dataObj = JSON.parse(JSON.stringify(data));
    for (var key of Object.keys(dataObj)) {
      let value = dataObj[key];
      delete value.filledKeys;
      dataObj[key] = value;
    }
    updateDoc(docRef, {
      welcomeCardDetails: dataObj,
    })
      .then(res)
      .catch(rej);
  });
}

// function generateId(length){
//   let result = "";
//   let characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   let charactersLength = characters.length;
//   for (let i = 0; i < length; i++) {
//     result += characters.charAt(Math.floor(Math.random() * charactersLength));
//   }
//   return result;
// }
