import React, { useState } from "react";
import "./MemberFilters.css";
import { DatePicker, Input, Select } from "antd";
import {
  CHAPTER_FILTER,
  FANS_NON_MEMBERS_FILTER,
  INACTIVE_FILTER,
  LEADERS_FILTER,
  LOCKED_ARCHIVED_FILTER,
  MEMBERS_ALL_FILTER,
  MEMBERS_NOT_ON_CHANT_FILTER,
  MEMBERS_ON_AUTO_RENEWAL_FILTER,
  MEMBERS_ON_CHANT_FILTER,
  OFFICIAL_CLUB_MEMBER_FILTER,
  OTHER_FILTER,
  PACKAGE_FILTER,
  RECEIVE_EMAILS_NO_FILTER,
  RECEIVE_EMAILS_YES_FILTER,
  SEASON_TICKET_HOLDER_FILTER,
  STATUS_ALL_FILTER,
  VOLUNTEER_NO_FILTER,
  VOLUNTEER_YES_FILTER,
} from "../../../../../constants/members";
import useGlobalStore from "../../../../../store/store";
import dayjs from "dayjs";
import { isMobile } from "../../../../../utils/helper";
import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import IconContainer from "../../../../../CommonComponents/IconContainer";
import { FilterIcon } from "../../../../../utils/icons";
import usePackages from "../../../../hooks/usePackages";

const MemberFilters = ({
  handleSearchInputChange,
  handleDropdownChange,
  handleFilterChange,
  filters,
  packages,
  chapters,
}) => {
  const { packagesLoading } = useGlobalStore((state) => state.packages);

  const { formLayoutLoading } = useGlobalStore((state) => state.formLayout);

  // let USER_ROLE_FILTERS = [
  //   "admin",
  //   "member",
  //   "ticket-scanner",
  //   "capo",
  //   "leader",
  //   "fan",
  // ];

  const STATUS_FILTERS = [
    STATUS_ALL_FILTER,
    MEMBERS_ALL_FILTER,
    MEMBERS_ON_CHANT_FILTER,
    MEMBERS_NOT_ON_CHANT_FILTER,
    MEMBERS_ON_AUTO_RENEWAL_FILTER,
    LEADERS_FILTER,
    FANS_NON_MEMBERS_FILTER,
    INACTIVE_FILTER,
    LOCKED_ARCHIVED_FILTER,
  ];

  const OTHERS_FILTERS = [
    OTHER_FILTER,
    OFFICIAL_CLUB_MEMBER_FILTER,
    SEASON_TICKET_HOLDER_FILTER,
    VOLUNTEER_YES_FILTER,
    VOLUNTEER_NO_FILTER,
    RECEIVE_EMAILS_YES_FILTER,
    RECEIVE_EMAILS_NO_FILTER,
  ];

  const { packagesBySeason } = usePackages();

  const updatedPackagesFilters =
    packagesBySeason?.map((item) => {
      return {
        value: item?.key,
        season: item?.season,
        label: item?.value,
      };
    }) || [];

  const updatedChapterFilters =
    chapters?.map((item) => {
      return {
        value: item,
        label: <span>{item}</span>,
      };
    }) || [];

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const [localDate, setLocalDate] = useState(
    filters.joinedSince ? dayjs.unix(filters.joinedSince) : null
  );

  const [showAllFilters, setShowAllFilters] = useState(false);

  return (
    <div>
      {isMobile() ? (
        <div
          className="member-filters-header"
          onClick={toggleExpand}
          style={isExpanded ? { borderRadius: "0%" } : undefined}
        >
          <span>Filters</span>
          {isExpanded ? (
            <UpOutlined style={{ marginLeft: "auto" }} />
          ) : (
            <DownOutlined style={{ marginLeft: "auto" }} />
          )}
        </div>
      ) : null}

      {(isExpanded || !isMobile()) && (
        <div className="members-filters-container">
          <div>
            <Input
              placeholder="Search Name/Email"
              prefix={<SearchOutlined />}
              style={{ width: isMobile() ? "100%" : "15rem" }}
              onChange={(e) => handleSearchInputChange(e.target.value)}
            />
          </div>

          <div className="members-filters">
            <Select
              className="member-filter-input"
              defaultActiveFirstOption
              // placeholder="Status"
              popupMatchSelectWidth={false}
              options={STATUS_FILTERS?.map((item) => ({
                value: item,
                label: <span>{item}</span>,
              }))}
              value={filters.status}
              onChange={(val) => handleDropdownChange("status", val)}
            />

            {updatedPackagesFilters?.length > 0 && (
              <Select
                loading={packagesLoading}
                className="member-filter-input"
                defaultActiveFirstOption
                popupMatchSelectWidth={false}
                value={filters.package}
                options={[
                  {
                    value: PACKAGE_FILTER,
                    label: <span>{PACKAGE_FILTER}</span>,
                  },
                  ...updatedPackagesFilters,
                ]}
                onChange={(val) => handleDropdownChange("package", val)}
              />
            )}

            {showAllFilters && updatedChapterFilters?.length > 0 && (
              <Select
                className="member-filter-input--chapters"
                defaultActiveFirstOption
                popupMatchSelectWidth={false}
                loading={formLayoutLoading}
                options={[
                  {
                    value: CHAPTER_FILTER,
                    label: <span>{CHAPTER_FILTER}</span>,
                  },
                  ...updatedChapterFilters,
                ]}
                value={filters.chapter}
                onChange={(val) => handleDropdownChange("chapter", val)}
              />
            )}
            {showAllFilters && (
              <Select
                className="member-filter-input"
                defaultActiveFirstOption
                popupMatchSelectWidth={false}
                options={OTHERS_FILTERS?.map((item) => ({
                  value: item,
                  label: <span>{item}</span>,
                }))}
                value={filters.other}
                onChange={(val) => handleDropdownChange("other", val)}
              />
            )}
            {showAllFilters && (
              <DatePicker
                style={{ minWidth: "12rem", maxWidth: "12rem" }}
                tabIndex="2"
                value={localDate}
                onChange={(date) => {
                  setLocalDate(date);
                  handleFilterChange(
                    "joinedSince",
                    date ? date.startOf("day").unix() : 0
                  );
                }}
                placeholder="Joined Since"
                className="form-control form-control-sm col"
                size={10}
              />
            )}

            <IconContainer
              icon={FilterIcon}
              onClick={() => setShowAllFilters((prev) => !prev)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberFilters;
