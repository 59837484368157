import { Table, Tooltip } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect, useDispatch, useSelector } from "react-redux";

import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { setGettingStartedItemStatus } from "../../../../redux/actions/adminData";
import {
  GUIDE_GROW,
  TOPIC_SELL_MERCHANDISE,
} from "../../../../constants/gettingStarted";
import { useNavigate } from "react-router-dom";
import { getStoreItems } from "../../../../services/firebaseService/endPoints/admin/store";
import { GripVertical } from "../../../../utils/icons";
import { getCurrencyUnicode } from "../../../../helperFunctions/util";
import DraggableBodyRow from "../../ourChants/DraggableBodyRow";
import "./Merchandise.css";
import ResponsiveMobileTab from "../../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";
import ChantButton from "../../../../CommonComponents/ChantButton/ChantButton";

const Merchandise = () => {
  const groupData = useSelector(selectCurrentGroupData);

  const [needsUpdate, setNeedsUpdate] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  const updateTable = (tableData) => {
    setTableData(tableData);
  };

  const fetchData = () => {
    getStoreItems(groupData.id).then((items) => {
      setTableData(items);
      if (items?.length) {
        dispatch(
          setGettingStartedItemStatus(
            groupData.id,
            GUIDE_GROW,
            TOPIC_SELL_MERCHANDISE,
            true
          )
        );
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const moveRow = useCallback((dragIndex, hoverIndex) => {
    const newData = JSON.parse(JSON.stringify(tableData));
    newData[dragIndex].sortIndex = hoverIndex + 1;
    let sortIndexChangeStep = hoverIndex - dragIndex > 0 ? -1 : 1;
    let i = sortIndexChangeStep > 0 ? hoverIndex : dragIndex + 1;
    let finalStep = sortIndexChangeStep < 0 ? hoverIndex : dragIndex - 1;
    for (; i <= finalStep; i++) {
      newData[i].sortIndex += sortIndexChangeStep;
    }

    updateTable(newData);
    setNeedsUpdate(true);
  }, []);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  let groupCurrencyISO = groupData.paymentDetails
    ? groupData.paymentDetails.currency || "USD"
    : "USD";
  let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

  const columns = [
    {
      width: 80,
      render: (data) => {
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <img src={GripVertical} alt="*" />
            {data && data.id && (
              <i
                className="fa fa-pencil cursor-pointer ml-3"
                onClick={() => {
                  navigate(`/admin/store/edit/${data.id}`);
                }}
              ></i>
            )}
          </div>
        );
      },
    },
    {
      title: "Item",
      className: "header-white",
      width: 250,
      dataIndex: "name",
    },
    {
      title: "Status",
      className: "header-white",
      width: 100,
      dataIndex: "status",
    },
    {
      title: "Display On",
      className: "header-white",
      width: 150,
      dataIndex: "displayOn",
      render: (list, record) =>
        record.status === "live" ? (
          <ul style={{ listStyleType: "none" }}>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          ""
        ),
    },
    {
      title: "Quantity",
      className: "header-white",
      dataIndex: "comboValues",
      render: (data, record) => {
        let quantity = 0;
        if (data) {
          quantity = data.reduce((acc, val) => acc + val.quantity, 0);
        } else {
          quantity = record.quantity;
        }
        return !isNaN(quantity) ? quantity : "";
      },
    },
    {
      title: "Sold",
      className: "header-white",
      dataIndex: "comboValues",
      render: (data, record) => {
        let sold = 0;
        if (data) {
          sold = data.reduce((acc, val) => acc + (val.sold || 0), 0);
        } else {
          sold = record.sold;
        }

        return <span>{!isNaN(sold) ? sold : ""}</span>;
      },
    },
    {
      title: "Remaining",
      className: "header-white",
      dataIndex: "comboValues",
      render: (data, record) => {
        let quantity = 0;
        if (data) {
          quantity = data.reduce((acc, val) => acc + val.quantity, 0);
        } else {
          quantity = record.quantity;
        }

        let sold = 0;
        if (data) {
          sold = data.reduce((acc, val) => acc + val.sold, 0);
        } else {
          sold = record.sold;
        }

        return (
          <span>
            {quantity && sold ? quantity - sold : quantity ? quantity : ""}
          </span>
        );
      },
    },
    {
      title: (
        <span>
          Revenue
          <Tooltip
            title={
              <div style={{ maxWidth: 400 }}>
                <span>
                  'Included' items (merchandise that is 'included' in a
                  membership packages) is not represented here as revenue. That
                  revenue is displayed as part of the membership package in
                  Member table.
                </span>
              </div>
            }
            placement="topLeft"
          >
            <span className="border-0">
              &nbsp;<i className="fa fa-question-circle-o"></i>
            </span>
          </Tooltip>
        </span>
      ),
      className: "header-white",
      dataIndex: "revenue",
      render: (data) => {
        let revenue = data || 0;
        return (
          <span>
            {!isNaN(revenue)
              ? `${groupCurrency}${revenue.toFixed(
                  Math.round(revenue) === revenue ? 0 : 2
                )}`
              : ""}
          </span>
        );
      },
    },
  ];

  let newItem = tableData.filter((elem) => elem.sortIndex === 0);

  let dataSource = tableData.sort(
    (row1, row2) => row1.sortIndex - row2.sortIndex
  );

  if (newItem.length > 0) {
    dataSource.forEach((elem) => {
      elem.sortIndex += 1;
    });
  }

  return (
    <div className="merchandise-container">
      <nav className="merchandise-nav">
        <ul
          style={{
            listStyle: "none",
            // padding: "2rem 3rem 0rem",
            padding: "0",
            paddingBottom: "1rem",
            margin: 0,
            display: "flex",
          }}
        >
          <ResponsiveMobileTab
            menuItems={[
              {
                key: "merchandise",
                label: "Merchandise",
              },
            ]}
            param={"merchandise"}
            primaryColor="var(--primary-background)"
            tabIndex={0}
          />
        </ul>

        <ChantButton
          onClick={() => {
            navigate("/admin/store/create");
          }}
        >
          Add Item
        </ChantButton>
      </nav>
      <div className="mt-3">
        <DndProvider backend={HTML5Backend}>
          <Table
            bordered
            columns={columns}
            dataSource={dataSource}
            components={components}
            onRow={(_, index) => ({
              index,
              moveRow,
            })}
            bodyStyle={{
              backgroundColor: "#ffffff",
            }}
            pagination={{
              position: ["bottomLeft"],
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            className="table-backdrop"
            scroll={{ x: "max-content" }}
          />
        </DndProvider>
      </div>
    </div>
  );
};

export default Merchandise;
