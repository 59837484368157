import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { useEffect } from "react";
import { fetchMembers } from "../../../../services/api/members";
import useGlobalStore from "../../../../store/store";

const useMembersData = () => {
  const groupData = useSelector(selectCurrentGroupData);

  const { membersList: membersListMap, membersLoading } = useGlobalStore(
    (state) => state.members
  );

  useEffect(() => {
    if (!membersListMap?.[groupData?.id]) {
      fetchMembers(groupData?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  return { membersList: membersListMap?.[groupData?.id], membersLoading };
};

export default useMembersData;
