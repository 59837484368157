import { useEffect, useMemo } from "react";
import useGlobalStore from "../../store/store";
import { selectCurrentGroupData } from "../../redux/selectors/adminData";
import { useSelector } from "react-redux";
import { fetchPackages } from "../../services/api/packages";
import dayjs from "dayjs";

const usePackages = () => {
  const groupData = useSelector(selectCurrentGroupData);

  const { packagesList, packagesLoading } = useGlobalStore(
    (state) => state.packages
  );

  useEffect(() => {
    if (!packagesList?.[groupData?.id]) {
      fetchPackages(groupData?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  const getPackagesWithTimeline = (packages) => {
    let packagesWithTimeline = [];

    if ((packages?.length || 0) === 0) return packagesWithTimeline;

    packages.forEach((pkg) => {
      if (!pkg.packageTimeline) {
        const packageName = pkg?.name
          ? pkg?.season
            ? `${pkg.name} (${pkg.season})`
            : `${pkg.name}`
          : "";

        const packageKey = pkg?.id
          ? pkg?.season
            ? `${pkg?.id}|${pkg?.season}`
            : `${pkg?.id}`
          : "";

        packagesWithTimeline.push({
          id: pkg.id,
          name: pkg.name,
          season: pkg.season?.toString() || "",
          key: packageKey,
          value: packageName,
          expiryDate: pkg?.packageExpiryDate || 0,
        });
      }

      if (pkg.packageTimeline) {
        Object.values(pkg.packageTimeline).forEach((timeline) => {
          if (timeline?.name && timeline?.season) {
            packagesWithTimeline.push({
              id: pkg.id,
              name: timeline.name,
              season: timeline.season.toString(),
              value: `${timeline.name} (${timeline.season.toString()})`,
              key: pkg.id + "|" + timeline.season.toString(),
              expiryDate: timeline?.expiryDate || 0,
            });
          }
        });
      }
    });

    return packagesWithTimeline;
  };

  const packagesBySeason = useMemo(
    () =>
      getPackagesWithTimeline(packagesList?.[groupData?.id]).sort(
        (a, b) => Number(b?.season || 0) - Number(a?.season || 0)
      ),
    [groupData?.id, packagesList]
  );

  const activePackages = packagesBySeason?.filter(
    (pkg) => pkg.expiryDate >= dayjs().unix()
  );

  return {
    currentPackages: packagesList?.[groupData?.id],
    packagesBySeason,
    packagesLoading,
    activePackages,
  };
};

export default usePackages;
