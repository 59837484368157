import { Popconfirm, Spin, Table, Tooltip } from "antd";
import React from "react";
import {
  getCurrencyUnicode,
  postUpdateCDNUtil,
  switchCollapseHorizontal,
} from "../../../helperFunctions/util";
import { connect } from "react-redux";
import {
  fbDeletePartner,
  fbGetDonationDataForGroup,
  fbGetPartners,
  setPartnerSortOrder,
} from "../../../services/firebaseService/endPoints/admin/partners";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import GripVertical from "../../../images/grip-vertical.svg";
import DonationReport from "./Report";
import moment from "moment";
import { json2csv } from "json-2-csv";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_SETUP,
  TOPIC_ADD_PARTNERS,
} from "../../../constants/gettingStarted";
import DraggableBodyRow from "../ourChants/DraggableBodyRow";
import { withRouter } from "../../../utils/helper";
import { DeleteIcon, EditIcon } from "../../../utils/icons";

class PartnersList extends React.Component {
  state = {
    tableData: [],
  };

  handleEditPartner = (data) => {
    this.props.router.navigate(`/admin/partners/edit/${data.id}`);
  };

  handleDeletePartner = (id) => {
    fbDeletePartner(this.props.currentGroup, id).then(() => {
      this.fetchPartnerList();
    });
  };

  moveRow = (dragIndex, hoverIndex) => {
    const newData = JSON.parse(JSON.stringify(this.state.tableData));
    newData[dragIndex].sortIndex = hoverIndex + 1;
    let sortIndexChangeStep = hoverIndex - dragIndex > 0 ? -1 : 1;
    let i = sortIndexChangeStep > 0 ? hoverIndex : dragIndex + 1;
    let finalStep = sortIndexChangeStep < 0 ? hoverIndex : dragIndex - 1;
    for (; i <= finalStep; i++) {
      newData[i].sortIndex += sortIndexChangeStep;
    }

    this.setState(() => {
      return { tableData: newData, needsUpdate: true };
    });
  };

  changeTab = (e) => {
    this.props.router.navigate(`/admin/partners/${e.target.name}`, {
      replace: true,
    });
  };

  render() {
    const components = {
      body: {
        row: DraggableBodyRow,
      },
    };
    const columns = [
      {
        title: "Action",
        width: 120,
        className: "header-white",
        render: (data) => {
          return (
            <div className="flex-center" style={{ gap: "4px" }}>
              <img src={GripVertical} alt="*" />
              <Tooltip title="edit">
                <i
                  className="ml-1"
                  onClick={() => this.handleEditPartner(data)}
                >
                  <img src={EditIcon} style={{ width: 25, height: 25 }} />
                </i>
              </Tooltip>
              <Popconfirm
                onCancel={() => {}}
                onConfirm={() => this.handleDeletePartner(data.id)}
                title="Are You Sure? Do not delete if donations have been collected. To hide parter, unselect all 'Display On' options in the form"
              >
                <Tooltip title="delete">
                  <i className="ml-1">
                    <img src={DeleteIcon} style={{ width: 25, height: 25 }} />
                  </i>
                </Tooltip>
              </Popconfirm>
            </div>
          );
        },
      },
      {
        title: "Partner",
        className: "header-white",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "Active?",
        className: "header-white",
        key: "active",
        dataIndex: "status",
      },
      {
        title: "Displayed",
        className: "header-white",
        key: "displayed",
        dataIndex: "displayOn",
        render: (data) => data && data.join(", "),
      },
      {
        title: "This Year($)",
        className: "header-white",
        key: "currentYearDonations",
        dataIndex: "currentYearDonations",
        render: (data) => data && parseFloat(`${data}`),
      },
      {
        title: "Total donations($)",
        className: "header-white",
        key: "totalDonations",
        dataIndex: "totalDonations",
        render: (data) => data && parseFloat(`${data}`),
      },
    ];

    let primaryColor = "";
    let color = "#ffffff";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
      if ([r, g, b].filter((e) => `${e}` === "255").length === 3) {
        color = `rgb(0, 0, 0)`;
      }
    }

    let dataSource = this.state.tableData.sort(
      (row1, row2) => row1.sortIndex - row2.sortIndex
    );

    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    const downloadDonations = () => {
      try {
        this.setState({ downloading: true });

        fbGetDonationDataForGroup(this.props.currentGroup).then((allData) => {
          let json = [];
          for (let donationData of allData) {
            let row = {
              Name: donationData.userName,
              Partner: donationData.partnerName,
              Amount: `${groupCurrency}${donationData.amount.toFixed(2)}`,
              Date: donationData.date
                ? moment
                    .unix(Math.floor(donationData.date / 1000))
                    .format("MM/DD/YYYY")
                : "",
              Email: donationData.userEmail,
            };
            json.push(row);
          }

          const csv = json2csv(json);

          const a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(
            new Blob([csv], { type: "text/csv" })
          );
          a.download = "donation-report.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          this.setState({ downloading: false });
        });
      } catch (error) {
        this.setState({ downloading: false });
      }
    };

    return (
      <div className="col">
        <div className="col" style={{ padding: "30px 16px" }}>
          <ul className="nav nav-tabs" style={{ position: "relative" }}>
            <li
              className="nav-item"
              onClick={this.changeTab}
              style={
                this.props.router.params.path === "list"
                  ? {
                      fontSize: 22,
                      fontWeight: "bold",
                      borderBottom: `4px solid ${primaryColor}`,
                      color: "black",
                    }
                  : {
                      fontSize: 22,
                      fontWeight: "bold",
                      color: "black",
                    }
              }
            >
              <a name="list">Partners</a>
            </li>
            <li
              className="nav-item"
              onClick={this.changeTab}
              style={
                this.props.router.params.path === "report"
                  ? {
                      marginLeft: 24,
                      fontSize: 22,
                      fontWeight: "bold",
                      borderBottom: `4px solid ${primaryColor}`,
                      color: "black",
                    }
                  : {
                      marginLeft: 24,
                      fontSize: 22,
                      fontWeight: "bold",
                      color: "black",
                    }
              }
            >
              <a name="report">Donations</a>
            </li>
            <li
              className="ml-auto align-items-center collapsible-horizontal tab-menu mb-1"
              id="location-utilities"
              style={{ marginLeft: "auto" }}
            >
              <a
                class="icon"
                style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                onClick={() => switchCollapseHorizontal("location-utilities")}
              >
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
              </a>

              <div
              // className="d-flex justify-content-end"
              >
                {this.props.router.params.path === "report" && (
                  <button
                    className="default-text-color"
                    style={{
                      backgroundColor: primaryColor,
                      borderRadius: 5,
                      border: "0px none",
                      boxShadow: "transparent 0px 0px 0px",
                      outline: "none",
                      padding: "5px 10px",
                      width: "150px",
                      alignSelf: "flex-end",
                      color: color,
                    }}
                    onClick={downloadDonations}
                  >
                    {this.state.downloading ? (
                      <Spin size="small" spinning={true} />
                    ) : (
                      "Download"
                    )}
                  </button>
                )}
              </div>
              <div className="show-for-mobile">
                <div className="menu-list">
                  {this.props.router.params.path === "report" && (
                    <div
                      htmlFor="uploader"
                      style={{
                        backgroundColor: "#ffffff",
                        padding: "5px 10px",
                        color: "#000000",
                      }}
                      onClick={() => {}}
                    >
                      <span>Download</span>
                    </div>
                  )}
                </div>
              </div>
            </li>
          </ul>
          {this.props.router.params.path === "list" && (
            <div className="mt-2">
              <div className="row">
                <div className="col">
                  <p>
                    Add community and commercial partners. Collect donations
                    (requires Stripe account from Chant). Set to appear across
                    the app, website and stadium web pages. Consider adding your
                    group as a partner to collect donations for tifo, operating
                    expenses, etc.
                  </p>
                  <p>
                    Partners with a discount and/or QR code uploaded will also
                    appear under the Member Card as an easy way to claim the
                    discount.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <button
                  className="default-text-color"
                  style={{
                    backgroundColor: primaryColor,
                    borderRadius: 5,
                    border: "0px none",
                    boxShadow: "transparent 0px 0px 0px",
                    outline: "none",
                    padding: "5px 10px",
                    width: "150px",
                    alignSelf: "flex-end",
                    color: color,
                  }}
                  onClick={() => {
                    this.props.router.navigate("/admin/partners/add");
                  }}
                >
                  Add Partner
                </button>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <div className="d-flex">
                    {this.state.needsUpdate && (
                      <button
                        className="mr-2"
                        style={{
                          backgroundColor: "#ff4040",
                          borderRadius: 5,
                          border: "0px none",
                          boxShadow: "transparent 0px 0px 0px",
                          outline: "none",
                          padding: "5px 10px",
                          color: "#ffffff",
                          alignSelf: "flex-end",
                        }}
                        onClick={() => {
                          let idOrderMap = this.state.tableData.reduce(
                            (acc, val, index) => {
                              acc[val.id] = val.sortIndex || index;
                              return acc;
                            },
                            {}
                          );

                          setPartnerSortOrder(
                            idOrderMap,
                            this.props.currentGroup
                          ).then(() => {
                            this.setState({
                              needsUpdate: false,
                            });
                            postUpdateCDNUtil(this.props.currentGroup);
                          });
                        }}
                      >
                        Save Order
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <DndProvider backend={HTML5Backend}>
                <Table
                  bordered
                  columns={columns}
                  components={components}
                  onRow={(_, index) => ({
                    index,
                    moveRow: this.moveRow,
                  })}
                  dataSource={dataSource}
                  className="table-backdrop"
                  bodyStyle={{ backgroundColor: "#ffffff" }}
                  pagination={{
                    position: ["bottomLeft"],
                    defaultPageSize: 100,
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50, 100],
                  }}
                />
              </DndProvider>
            </div>
          )}
          {this.props.router.params.path === "report" && <DonationReport />}
        </div>
      </div>
    );
  }

  fetchPartnerList = () => {
    fbGetPartners(this.props.currentGroup).then((tableData) => {
      if (tableData.length > 0) {
        this.props.dispatch(
          setGettingStartedItemStatus(
            this.props.currentGroup,
            GUIDE_SETUP,
            TOPIC_ADD_PARTNERS,
            true
          )
        );
      }
      this.setState({
        tableData,
      });
    });
  };

  componentDidMount = () => {
    this.fetchPartnerList();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.fetchPartnerList();
    }
  };
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(withRouter(PartnersList));
