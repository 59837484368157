import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  deleteDoc,
} from "@firebase/firestore";
import { db } from "../../connection";
import { uploadImage } from "./website";
import logger from "../../../../utils/logger";
import { postUpdateCDNUtil } from "../../../../helperFunctions/util";

export function fbGetPartners(groupId) {
  return new Promise((resolve, reject) => {
    getDocs(collection(db, "partners", groupId, "details"))
      .then((querySnapshot) => {
        let dataArr = [];
        querySnapshot.docs.map((doc) => {
          let data = doc.data() || {};
          data.id = doc.id;
          let ytdField = `${new Date().getFullYear()}totalDonations`;
          let totalDonations = data.totalDonations || {};
          data.currentYearDonations = 0;
          data.totalDonations = Object.values(totalDonations).reduce(
            (acc, val) => {
              acc = acc + val;
              return acc;
            },
            0
          );
          if (totalDonations[ytdField]) {
            data.currentYearDonations = totalDonations[ytdField];
          }
          dataArr.push(data);
        });
        resolve(dataArr);
      })
      .catch((err) => {
        let message = "There was an error fetching the partners";
        reject({ message });
      });
  });
}

export function getPartnerDetails(groupId, docId) {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "partners", groupId, "details", docId))
      .then((doc) => {
        let data = {};
        if (doc.exists()) {
          data = doc.data();
          data.id = doc.id;
        }
        resolve(data);
      })
      .catch((err) => {
        let message = "There was an error fetching the partners";
        reject({ message });
      });
  });
}

export function fbSavePartnerDetails(data, groupId, docId) {
  return new Promise(async (res, rej) => {
    let docRef = doc(collection(db, "partners", groupId, "details"));
    if (docId) {
      docRef = doc(db, "partners", groupId, "details", docId);
    }

    data.showLogo = false;
    data.showQrImage = false;

    if (data.logo) {
      if (typeof data.logo === "object") {
        await uploadImage(data.logo, `${groupId}/partner/${docRef.id}/logo`, 0);
        data.showLogo = true;
      } else if (typeof data.logo === "string") {
        data.showLogo = true;
      }
    }

    if (data.qrImage) {
      if (typeof data.qrImage === "object") {
        await uploadImage(
          data.qrImage,
          `${groupId}/partner/${docRef.id}/qrImage`,
          0
        );
        data.showQrImage = true;
      } else if (typeof data.qrImage === "string") {
        data.showQrImage = true;
      }
    }

    let galleryImageUrls = [];
    let imageIndex = 0;
    for (var image of data.galleryImages || []) {
      if (image !== null) {
        if (typeof image === "object") {
          let downloadUrl = await uploadImage(
            image,
            `${groupId}/partner/${docRef.id}/gallery/${imageIndex}`,
            imageIndex
          ).then(({ downloadUrl }) => {
            return downloadUrl;
          });
          galleryImageUrls.push(downloadUrl);
        } else if (typeof image === "string") {
          galleryImageUrls.push(image);
        }
        imageIndex++;
      }
    }

    data.galleryImageCount = galleryImageUrls.length;

    let updateData = {
      name: data.name,
      details: data.details,
      showLogo: data.showLogo,
      showQrImage: data.showQrImage,
      galleryImageCount: data.galleryImageCount,
      discounts: data.discounts,
      collectDonations: data.collectDonations,
      suggestedAmounts: data.suggestedAmounts,
      displayOn: data.displayOn,
      processingFee: data.processingFee,
      status: data.status,
    };

    setDoc(docRef, updateData, {
      merge: true,
    })
      .then(() => {
        res();
      })
      .catch(rej);
  });
}

export function fbDeletePartner(groupId, docId) {
  let docRef = doc(db, "partners", groupId, "details", docId);
  return new Promise((res, rej) => {
    deleteDoc(docRef).then(res).catch(rej);
  });
}

export function setPartnerSortOrder(idOrderMap, groupId) {
  return new Promise((resolve, reject) => {
    let collectionRef = collection(db, "partners", groupId, "details");

    let writePromises = Object.entries(idOrderMap).map((entry) =>
      updateDoc(doc(collectionRef, entry[0]), { sortIndex: entry[1] })
    );
    Promise.all(writePromises)
      .then(() => resolve())
      .catch(reject);
  });
}

export function fbGetDonationDataForPartner(groupId, partnerId, partnerName) {
  return new Promise((resolve, reject) => {
    let collectionRef = collection(
      db,
      "partners",
      groupId,
      "details",
      partnerId,
      "donations"
    );

    let data = [];

    getDocs(collectionRef)
      .then((snap) => {
        snap.docs.map((doc) => {
          let docData = doc.data() || {};
          (docData.purchaseDetails || []).map((detail) => {
            let updateData = {
              userId: doc.id,
              userName: docData.userName,
              userEmail: docData.userEmail,
              partnerName: partnerName,
              partnerId: partnerId,
              amount: detail.totalCost,
              date: detail.timestamp,
              paymentIntentId: detail.paymentIntentId || "",
              source: detail.source,
            };
            data.push(updateData);
          });
        });

        resolve(data);
      })
      .catch(reject);
  });
}

export function fbGetDonationDataForGroup(groupId) {
  return new Promise(async (resolve, reject) => {
    let collectionRef = collection(db, "partners", groupId, "details");
    let donations = [];

    let snap = await getDocs(collectionRef);
    for (let doc of snap.docs) {
      let docData = doc.data() || {};
      let partnerName = docData.name;
      let donationForPartner = await fbGetDonationDataForPartner(
        groupId,
        doc.id,
        partnerName
      ).catch((err) => {
        logger.error(groupId, doc.id, err);
      });
      donations.push(...(donationForPartner || []));
    }

    resolve(donations);
  });
}
