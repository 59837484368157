import React, { useEffect, useState } from "react";
import { fbUpdateGroup } from "../../../../services/firebaseService/endPoints/group";
import { fbGetGroupSettings } from "../../../../services/firebaseService/endPoints/admin/groupConfig";
import LoadingModal from "../../../commons/LoadingModal";
import { Alert } from "bootstrap/dist/js/bootstrap.min";
import FUpload from "../../../commons/formFields/FUpload";
import ColorPicker from "../../../commons/ColorPicker";
import { CirclePicker } from "react-color";
import {
  AppIcon1,
  ChantLogoIcon,
  GooglePlayIcon,
} from "../../../../utils/icons";
import DemoAppView from "../../../../images/demo-app-view.png";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { doc, getDoc } from "firebase/firestore";
import { rgbToHex } from "../../../../helperFunctions/util";
import { Modal } from "antd";
import ChantButton from "../../../../CommonComponents/ChantButton/ChantButton";
import { db } from "../../../../services/firebaseService/connection";
import "./GroupDesign.css";
import AppIconModal from "./AppIconModal";

const GroupDesign = () => {
  const [logoList, setLogoList] = useState([]);
  const [primaryColor, setPrimaryColor] = useState({
    r: "",
    g: "",
    b: "",
    a: "",
  });
  const [secondaryColor, setSecondaryColor] = useState({
    r: "",
    g: "",
    b: "",
    a: "",
  });
  const [stripesColor, setStripesColor] = useState({
    r: "",
    g: "",
    b: "",
    a: "",
  });
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [textSecondaryColor, setTextSecondaryColor] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [imageChanged, setImageChanged] = useState(false);
  const [error, setError] = useState({ show: false, message: "" });
  const [updated, setUpdated] = useState(true);
  const [logoFile, setLogoFile] = useState({});
  const [preview, setPreview] = useState("");
  const [showIconModal, setShowIconModal] = useState(false);
  const [colors, setColors] = useState([
    "#000000",
    "#B80000",
    "#DB3E00",
    "#FCCB00",
    "#008B02",
    "#006B76",
    "#1273DE",
    "#004DCF",
    "#FFFFFF",
  ]);

  const [selectedAppIconIndex, setSelectedAppIconIndex] = useState(6);

  const handleColorChange = (color, type) => {
    if (type === "primaryColor") {
      setPrimaryColor(color);
    }
    if (type === "secondaryColor") {
      setSecondaryColor(color);
    }
    if (type === "stripesColor") {
      setStripesColor(color);
    }
    setUpdated(false);
  };

  const uploadAction = (file) => {
    setLogoFile(file);
    setUpdated(false);
  };

  const handleImageChange = ({ fileList }) => {
    if (fileList.length === 0) {
      setLogoFile({});
    } else {
      setLogoFile(fileList[0].originFileObj);
    }

    setLogoList(fileList);
    setImageChanged(true);
    setUpdated(false);
  };

  const handleConfigurationUpdate = (e) => {
    e.preventDefault();
    if (imageChanged && !logoFile.name) {
      setError({ show: true, message: "Group Logo is required" });
      return;
    } else {
      setIsLoading(true);
      setError({ ...error });
      e.preventDefault();
      let data = {
        configuration: {
          primaryColor: primaryColor,
          secondaryColor: secondaryColor,
          stripesColor: stripesColor,
          appIcon: String(selectedAppIconIndex + 1),
        },
      };
      if (/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(textPrimaryColor))
        data.configuration.textPrimaryColor = textPrimaryColor;
      if (/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(textSecondaryColor))
        data.configuration.textSecondaryColor = textSecondaryColor;

      fbUpdateGroup(data, groupData?.id, imageChanged && logoFile).then(() => {
        getGroupSettings(true);
      });
    }
  };

  const groupData = useSelector(selectCurrentGroupData);

  const getGroupSettings = (updated = false) => {
    fbGetGroupSettings(groupData?.id).then((configuration) => {
      let logoList = [];
      if (configuration.logo) {
        logoList = [{ uid: "-1", url: configuration.logo }];
      }

      setUpdated(updated);
      setPrimaryColor(configuration.primaryColor);
      setSecondaryColor(configuration.secondaryColor);
      setStripesColor(configuration.stripesColor);
      setIsLoading(false);
      setTextPrimaryColor(configuration.textPrimaryColor);
      setLogoList(logoList);
      setSelectedAppIconIndex(Number(configuration?.appIcon || "7") - 1);
    });

    getDoc(doc(db, "clubColors", groupData.clubId)).then((doc) => {
      if (doc.exists()) {
        let data = doc.data() || {};
        let colors = [];
        Object.keys(data).forEach((key) => {
          if (
            key.includes("color") &&
            data[key].r !== "" &&
            data[key].g !== "" &&
            data[key].b !== ""
          ) {
            let color = data[key];
            Object.keys(color).forEach((key) => {
              if (typeof color[key] === "string") {
                color[key] = parseInt(color[key]);
              }
            });
            let hexColor = rgbToHex(color.r, color.g, color.b);
            colors.push(hexColor);
          }
        });
        colors = Array.from(new Set([...colors, "#ffffff", "#000000"]));
        setColors(colors);
      }
    });
  };

  const handleSelectIcon = (iconId) => {
    setSelectedAppIconIndex(iconId);
    setUpdated(false);
  };

  const appIconImage = require(
    `../../../../images/app-icons/${String(selectedAppIconIndex + 1)}.png`
  );

  useEffect(() => {
    getGroupSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData.id]);

  return (
    <div className="col">
      <div style={{ padding: "30px 16px" }}>
        <LoadingModal loading={isLoading} />
        <ul className="nav nav-tabs">
          <li
            className="nav-item"
            style={{
              fontSize: 22,
              fontWeight: "bold",
              borderBottom: `4px solid var(--primary-background)`,
              color: "black",
            }}
          >
            <a>Design</a>
          </li>
        </ul>
        <form
          className="mb-4 p-3 px-md-5 py-md-4 mx-md-auto"
          style={{
            maxWidth: "1400px",
            width: "100%",
            border: "1px solid #dee2e6",
            borderRadius: "0.25rem",
            marginTop: 30,
            backgroundColor: "#ffffff",
            // boxShadow: "0px 3px 10px 1px",
          }}
          onSubmit={handleConfigurationUpdate}
        >
          {error.show && (
            <Alert message="Error" description={error.message} type="error" />
          )}
          <div className="col">
            <p style={{ fontWeight: 400 }}>
              Upload your group logo and select colors for the app icon, app
              splash screen and app header / website.
            </p>
          </div>

          <div className="row">
            <div className="col-md-4">
              <p style={{ fontWeight: "bold", fontSize: "1rem", margin: "0" }}>
                Group Name
              </p>
              <p>Request group name changes via support@chant.fan.</p>
            </div>

            <div className="col-md-8 flex-center">
              <p style={{ fontWeight: "bold", fontSize: "1rem", margin: "0" }}>
                {groupData.groupName}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <p style={{ fontWeight: "bold", fontSize: "1rem", margin: "0" }}>
                Group Logo
              </p>
              <p>
                Upload your group logo to be used across the app, website, and
                directory.
              </p>
            </div>

            <div className="col-md-8 flex-center">
              <FUpload
                action={uploadAction}
                fileList={logoList}
                onChange={handleImageChange}
                aspectRatio={1 / 1}
                onLoad={(preview) => {
                  setPreview(preview);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <p style={{ fontWeight: "bold", fontSize: "1rem", margin: "0" }}>
                App Icon (select color)
              </p>
              <p style={{ marginBottom: "6px" }}>
                The default app icon is red. Select a color that closely matches
                one of your club’s colors. Note: If a member is part of 2+
                groups their app icon will switch based on group last visited.
              </p>
              <ChantButton
                type="primary"
                onClick={() => setShowIconModal((prev) => !prev)}
              >
                Select
              </ChantButton>
            </div>

            <div className="col-md-8 flex-center">
              <img
                alt={`app-icon`}
                height={100}
                width={100}
                src={appIconImage}
              />
            </div>
          </div>

          <div className="col">
            <div style={{ paddingTop: "3rem" }}>
              <div>
                <p
                  style={{ fontWeight: "bold", fontSize: "1rem", margin: "0" }}
                >
                  Select Group Colors
                </p>
                <p style={{ marginBottom: "6px" }}>
                  Select from default club colors or enter custom RGB values.
                </p>
              </div>
              <div className="form-group my-row">
                <div className="col-md-6">
                  {/* Primary Club Color  */}
                  <div className="form-group border rounded">
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                    >
                      Header
                    </label>
                    <div className={"col-12"}>
                      <ColorPicker
                        color={primaryColor}
                        colorType="primaryColor"
                        handleColorChange={handleColorChange}
                        colorOptions={colors}
                      />
                    </div>
                  </div>

                  {/* Secondary Club Color */}
                  <div className="form-group border rounded">
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                    >
                      Border/Card Title
                    </label>
                    <div className={"col-12"}>
                      <ColorPicker
                        color={secondaryColor}
                        colorType="secondaryColor"
                        handleColorChange={handleColorChange}
                        colorOptions={colors}
                      />
                    </div>
                  </div>

                  {/* Strips Color */}
                  <div className="form-group border rounded">
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                    >
                      Stripes
                    </label>
                    <div className={"col-12"}>
                      <ColorPicker
                        color={stripesColor}
                        colorType="stripesColor"
                        handleColorChange={handleColorChange}
                        colorOptions={colors}
                      />
                    </div>
                  </div>

                  <div className="form-group my-row">
                    <label
                      className={`${window.screen.width > 500 ? "col-6" : "col-8"}`}
                      style={{ fontFamily: "Open Sans" }}
                    >
                      Chant
                    </label>
                    <CirclePicker
                      colors={["#000000", "#FFFFFF"]}
                      width="220px"
                      triangle={"hide"}
                      className="color-picker-border"
                      onChange={(val) => {
                        setTextPrimaryColor(val.hex);
                        setUpdated(false);
                      }}
                      color={textPrimaryColor}
                    />
                  </div>
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 30,
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      height: "81%",
                      backgroundColor: "#000000",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        flex: "0 0 26%",
                        backgroundColor: `rgba(${primaryColor.r},${primaryColor.g},${primaryColor.b},1)`,
                        borderBottom: `7px solid rgba(${secondaryColor.r},${secondaryColor.g},${secondaryColor.b},1)`,
                        position: "relative",
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#ffffff",
                          width: 150,
                          height: 150,
                          borderRadius: "50%",
                          border: `7px solid rgba(${secondaryColor.r},${secondaryColor.g},${secondaryColor.b},1)`,
                          position: "absolute",
                          left: "50%",
                          top: "10%",
                          transform: "translateX(-50%)",
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            preview
                              ? preview
                              : logoList[0]
                                ? logoList[0].url
                                : ChantLogoIcon
                          }
                          alt="logo"
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          flex: "0 0 5%",
                          backgroundColor: `rgba(${stripesColor.r},${stripesColor.g},${stripesColor.b},1)`,
                          marginRight: "5%",
                        }}
                      ></div>
                      <div
                        style={{
                          flex: "0 0 5%",
                          backgroundColor: `rgba(${stripesColor.r},${stripesColor.g},${stripesColor.b},1)`,
                          marginRight: "5%",
                        }}
                      ></div>
                      <span
                        style={{
                          color: textPrimaryColor,
                          fontSize: "30px",
                          alignSelf: "flex-end",
                          marginRight: "auto",
                          marginLeft: "15px",
                          fontFamily: "monospace",
                          fontWeight: "bold",
                        }}
                      >
                        Chant
                      </span>
                    </div>
                    <div
                      style={{
                        flex: "0 0 60%",
                        backgroundColor: "#181818",
                        marginTop: 80,
                        width: "94%",
                        alignSelf: "center",
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                        padding: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="col-md-5 ml-2"
                          style={{
                            color: "#ffffff",
                            fontFamily: "Open Sans",
                            fontWeight: "bolder",
                            padding: "2px 5px",
                            borderBottom: `5px solid rgba(${secondaryColor.r},${secondaryColor.g},${secondaryColor.b},1)`,
                          }}
                        >
                          VAR Poll
                        </span>
                        <i
                          className="fa fa-angle-up"
                          style={{
                            fontSize: 20,
                            color: "#6ac4f1",
                            fontWeight: "bolder",
                          }}
                        />
                      </div>
                      <hr />
                      <div>
                        <img
                          src={DemoAppView}
                          style={{ width: "100%" }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className={`btn btn-sm btn-primary default-text-color`}
              style={{
                backgroundColor: "var(--primary-background)",
                borderColor: "var(--primary-background)",
              }}
              disabled={updated}
            >
              {updated ? "Updated" : "Update changes"}
            </button>
          </div>

          <AppIconModal
            showIconModal={showIconModal}
            setShowIconModal={setShowIconModal}
            handleSelectIcon={handleSelectIcon}
            selectedAppIconIndex={selectedAppIconIndex}
          />
        </form>
      </div>
    </div>
  );
};

export default GroupDesign;
