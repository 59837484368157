import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import React from "react";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import ImageUploaderButton from "../website/ImageUploaderButton";
import {
  uploadImage,
  addPackage,
  getPackage,
  updatePackage,
} from "../../../services/firebaseService/endPoints/admin/members";
import { connect } from "react-redux";
import { getStoreItemsForRegistration } from "../../../services/firebaseService/endPoints/admin/store";
import {
  getCurrencyUnicode,
  postUpdateCDNUtil,
} from "../../../helperFunctions/util";
import { verifyStripeAccountId } from "../../../utils/errors";
import logger from "../../../utils/logger";
import PackageHistory from "./Packages/PackagesTable/PackageHistory";
import dayjs from "dayjs";

const statusDataList = ["Active", "Inactive"].reduce((acc, val) => {
  let key = val.toLocaleLowerCase();
  let value = val;
  acc.push({ key, value });
  return acc;
}, []);

const MONTHS = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const priceTypes = ["Per member", "Per adult", "Per couple", "Per family"];

const shippingOptions = [
  { value: "pickup", label: "Pick up only" },
  { value: "shipping", label: "Shipping only" },
  { value: "both", label: "Option of shipping or pickup" },
  { value: "none", label: "Not applicable" },
];
const productChoices = ["None", "1 per member", "1 per adult", "1 per package"];

const allTshirtSizes = [
  "Youth Small",

  "Youth Medium",

  "Youth Large",

  "Youth XL",

  "Adult XS",

  "Adult Small",

  "Adult Medium",

  "Adult Large",

  "Adult XL",

  "Adult 2XL",

  "Adult 3XL",

  "Women’s XS",

  "Women’s Small",

  "Women’s Medium",

  "Women’s Large",

  "Women’s XL",

  "Women’s 2XL",
];

class MemberPackageForm extends React.Component {
  state = {
    status: "active",
    season: new Date().getFullYear(),
    name: "",
    desc: "",
    image: undefined,
    price: {
      amount: 0,
      type: "Per member",
      shipping: 0,
    },
    childPrice: {
      amount: 0,
      shipping: 0,
    },
    offerShipping: "",
    shippingNote: "",
    pickupNote: "",
    productChoice: "None",
    products: [],
    askTshirtSize: false,
    tshirtSizes: [],
    showSizes: false,
    storeItems: [],
    includesChildPrice: false,
    packageExpiryDate: null,
    offerAutorenewal: false,
    packageTimeline: {},
    autoRenewalDate: {
      month: "",
      date: "",
    },
    emailMemo: "",
    editChanges: {
      priceChange: false,
      nameChange: false,
      childPriceChange: false,
    },
    saving: false,
  };

  savePackage = async () => {
    try {
      const { price, childPrice } = this.state;

      const totalPrice =
        price?.amount +
        price?.shipping +
        childPrice?.amount +
        childPrice?.shipping;

      if (totalPrice === 0) {
        alert(
          "Packages can not be free as the purchase goes through Stripe. Use an alternate method to onboard members if there is no fee to be collected."
        );
        return;
      }

      if (
        !verifyStripeAccountId(
          this.props?.adminData?.data?.paymentDetails?.stripeAccountId
        )
      ) {
        return;
      }

      this.setState({ saving: true });
      let data = this.state;
      data = {
        ...data,
        packageExpiryDate: data.packageExpiryDate
          ? dayjs(data.packageExpiryDate).unix()
          : null,
      };

      // if (this.state.offerAutorenewal) {
      //     if (!this.state.autoRenewalDate.month || !this.state.autoRenewalDate.date) {
      //         alert("Auto-renewal date is required!");
      //         return;
      //     }
      // }

      if (!this.state.name) {
        alert("Package name is required");
        this.setState({ saving: false });
        return;
      }

      if (!this.state.packageExpiryDate) {
        alert("Package expiry date is required");
        this.setState({ saving: false });
        return;
      }

      let image = "";
      if (data.image && typeof data.image !== "string") {
        image = await uploadImage(
          data.image,
          `regForm/${this.props.currentGroup}/pack/${Date.now()}`
        ).then(({ downloadUrl }) => downloadUrl);
        data.image = image;
      } else {
        if (!data.image) {
          alert("Image is required");
          this.setState({ saving: false });
          return;
        }
      }

      if (!this.state.offerShipping) {
        alert("Please select shipping option.");
        this.setState({ saving: false });
        return;
      }

      let editChanges = JSON.parse(JSON.stringify(data.editChanges));

      data.packageTimeline = {
        ...data.packageTimeline,
        [this.state.season]: {
          name: this.state?.name,
          expiryDate: dayjs(this.state?.packageExpiryDate).unix() || null,
          season: this.state?.season,
          package: this.state.name,
          offerAutorenewal: this.state?.offerAutorenewal,
          price: this.state?.price,
          childPrice: this.state?.childPrice,
          products: this.state?.products,
          includesChildPrice: this.state?.includesChildPrice,
        },
      };

      delete data.showSizes;
      delete data.storeItems;
      delete data.editChanges;

      let paymentDetails = this.props.adminData.data.paymentDetails || {};
      let stripeId = paymentDetails.stripeAccountId || "";
      let currency = (paymentDetails.currency || "usd").toLowerCase();

      if (this.props.id) {
        await updatePackage(
          data,
          this.props.id,
          this.props.currentGroup,
          editChanges,
          stripeId,
          currency
        );
      } else {
        await addPackage(data, this.props.currentGroup, stripeId, currency);
      }
      postUpdateCDNUtil(this.props.currentGroup);

      this.props.close();
    } catch (error) {
      this.setState({ saving: false });
      logger.error("Save Package Error: ", error);
    }
  };

  componentDidMount() {
    let promises = [];
    if (this.props.id) {
      let packagePromise = getPackage(
        this.props.currentGroup,
        this.props.id
      ).then((data) => {
        this.setState({
          ...data,
          packageExpiryDate: data.packageExpiryDate
            ? dayjs(data.packageExpiryDate * 1000)
            : null,
          childPrice: data.childPrice || {
            amount: 0,
            shipping: 0,
          },
        });
      });

      promises.push(packagePromise);
    }

    let productsPromise = getStoreItemsForRegistration(
      this.props.currentGroup
    ).then((items) => {
      this.setState({ storeItems: items });
    });

    promises.push(productsPromise);

    Promise.all(promises);
  }

  // handleDateChange = (name, value) => {
  //   if (!Array.isArray(value)) value = dayjs(value).isValid() ? value : "";
  //   let hours = 0,
  //     minutes = 0,
  //     seconds = 0;
  //   // return;
  //   if (value) {
  //     this.setState({
  //       [name]: dayjs(value)
  //         .set("hour", hours)
  //         .set("minute", minutes)
  //         .set("second", seconds),
  //     });
  //   }
  // };

  handleDateChange = (name, value) => {
    if (!Array.isArray(value)) {
      const parsedValue = dayjs(value);
      if (!parsedValue.isValid()) {
        this.setState({ [name]: null }); // Or set to a default value
        return;
      }
      value = parsedValue;
    }

    this.setState({
      [name]: value.set("hour", 0).set("minute", 0).set("second", 0),
    });
  };

  render() {
    let filteredStoreItems = (this.state.storeItems || []).filter((item) => {
      if (this.state.products.includes(item.id)) {
        return true;
      }
      return item.status === "live";
    });

    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    const { Panel } = Collapse;
    return (
      <Modal
        width={1000}
        title="Add Package"
        centered
        open={this.props.visible || true}
        footer={null}
        onOk={() => {}}
        onCancel={() => {
          this.props.close();
        }}
      >
        <div style={{ width: "100%" }}>
          <p>
            Members can only buy one package per email per season. <br />{" "}
            <b>Important:</b> Ensure members renew with the same email or it
            will not update their profile and Chant access. <br /> Ensure
            subscription ‘Stripe’ email settings are managed (see Packages /
            Setup for details). <br /> All subscriptions auto-renew on the
            package expiration date. Do not change the package expiration date
            (for the same season) without engaging Chant support.
            <br />
            <b>Add/Edit Package:</b> Groups offering auto-renewal should reuse
            the same package each season. Changing the ‘season’ will create a
            new ‘version’ of the package (see version history below) and must
            include a new package expiration date. <br /> Reach out to
            support@chant.fan when managing subscriptions.
          </p>
          <div className="col">
            <div className="row" style={{ width: "95%" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          Status<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <FSelect
                          width="100%"
                          value={this.state.status}
                          onChange={(e) => {
                            this.setState({
                              status: e.target.value,
                            });
                          }}
                          dataList={statusDataList}
                          dataKey="key"
                          dataValue="value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          Season<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <FSelect
                          width="100%"
                          value={this.state.season}
                          onChange={(e) => {
                            if (this.props.id) {
                              let isConfirmed = window.confirm(
                                "Warning: If updating the package for the following season you should update both the package expiration date and the season. Members can purchase one package per season."
                              );
                              if (isConfirmed)
                                this.setState({
                                  season: e.target.value,
                                });
                            } else {
                              this.setState({
                                season: e.target.value,
                              });
                            }
                          }}
                          dataList={[
                            new Date().getFullYear(),
                            new Date().getFullYear() + 1,
                          ]}
                          dataOnlyValue={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          Package Name<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <FInput
                          value={this.state.name}
                          name="name"
                          onChange={(e) => {
                            let editChanges = this.state.editChanges;
                            editChanges.nameChange = true;
                            editChanges.productId = this.state.stripeProductId;
                            this.setState({
                              name: e.target.value,
                              editChanges: editChanges,
                            });
                          }}
                          maxLength={50}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Description</label>
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="form-group row">
                              <textarea
                                style={{ marginLeft: "12px" }}
                                className="form-control form-control-sm col-12"
                                value={this.state.desc}
                                name="desc"
                                onChange={(e) => {
                                  this.setState({
                                    desc: e.target.value,
                                  });
                                }}
                                maxLength={500}
                                rows={2}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Package Expiration Date</label>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip
                          placement="topLeft"
                          title={
                            <div style={{ width: 400 }}>
                              Important: After this date, members who have not
                              renewed will be locked out of Chant. Include a
                              ‘grace period’ allowing time to renew after your
                              next membership drive begins.
                            </div>
                          }
                        >
                          <span className="border-0">
                            &nbsp;<i class="fa fa-question-circle-o"></i>
                          </span>
                        </Tooltip>
                      </div>
                      <div className="col-md-8">
                        <div>
                          <div className="col-md-8">
                            <div className="row">
                              <DatePicker
                                tabIndex="2"
                                style={{ marginLeft: "12px" }}
                                value={this.state.packageExpiryDate}
                                onChange={(value) => {
                                  if (this.props.id) {
                                    let isConfirmed = window.confirm(
                                      "Warning: Do not edit the expiration date for packages using auto-renewal without engaging Chant support. If updating the package for the following season you should update both the package expiration date AND the season (new package version is created)."
                                    );
                                    if (isConfirmed) {
                                      this.handleDateChange(
                                        "packageExpiryDate",
                                        value
                                      );
                                    }
                                  } else {
                                    this.handleDateChange(
                                      "packageExpiryDate",
                                      value
                                    );
                                  }
                                }}
                                className="form-control form-control-sm"
                                size={10}
                              />
                            </div>
                          </div>
                          <div>
                            Package expires on this date (if not on
                            auto-renewal).
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ padding: "1rem 0rem" }}>
                  <div className="col-md-9">
                    <div className="row mt-2" style={{ flexFlow: "nowrap" }}>
                      <div className="col-md-4">
                        <label>Offer auto-renewal?</label>
                      </div>
                      <div
                        className="row"
                        style={{ flexFlow: "nowrap", width: "fit-content" }}
                      >
                        <Checkbox
                          style={{ width: "auto" }}
                          checked={this.state.offerAutorenewal}
                          onChange={(e) => {
                            this.setState({
                              offerAutorenewal: e.target.checked,
                            });
                          }}
                          value="secondary"
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          tabIndex="2"
                        />
                        <div className="ml-2">
                          <span>
                            If selected members will be offered option to
                            auto-renew the package.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label>
                          Image<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <ImageUploaderButton
                            text="Upload Image"
                            images={[this.state.image]}
                            onSave={(images) => {
                              this.setState({
                                image: images[0],
                              });
                            }}
                            aspectRatio={1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label>
                          Price<span style={{ color: "red" }}>*</span>
                        </label>
                        <Tooltip
                          placement="topLeft"
                          title={() => (
                            <div style={{ width: 400 }}>
                              <p style={{ marginBottom: 0 }}>Per Member</p>
                              <p>
                                One price for all members. No discounts for
                                couples/families. No children added separately.
                              </p>
                              <br />
                              <p style={{ marginBottom: 0 }}>Per Adult</p>
                              <p style={{ marginBottom: 0 }}>
                                {" "}
                                Allows a separate price for children.
                              </p>
                              <p>
                                Child package does not allow Chant access. Only
                                child’s name is collected. A member number is
                                assigned. Details are stored with parent
                                account.
                              </p>
                              <br />
                              <p style={{ marginBottom: 0 }}>Per Couple</p>
                              <p>
                                {" "}
                                Provide a couples discount and separate price
                                for children.
                              </p>
                              <br />
                              <p style={{ marginBottom: 0 }}>Per Family</p>
                              <p>
                                {" "}
                                One price for the entire family. Member prompted
                                to select '# of children' at no additional cost.
                              </p>
                            </div>
                          )}
                        >
                          <span className="border-0">
                            &nbsp;<i className="fa fa-question-circle-o"></i>
                          </span>
                        </Tooltip>
                      </div>
                      <div className="col-md-8">
                        <div
                          className="row align-items-center"
                          style={{ flexWrap: "nowrap" }}
                        >
                          <input
                            style={{ marginLeft: "12px", width: "5rem" }}
                            prefix="$"
                            type="Number"
                            onChange={(e) => {
                              let price = this.state.price;
                              let editChanges = this.state.editChanges;
                              editChanges.productId =
                                this.state.stripeProductId;
                              let value = e.target.value;
                              if (Number(value) >= 0) {
                                if (
                                  value.includes(".") &&
                                  value.length - value.indexOf(".") > 2
                                ) {
                                  value = value.slice(
                                    0,
                                    value.indexOf(".") + 3
                                  );
                                }
                                editChanges.priceChange = true;
                                this.setState({
                                  price: {
                                    ...price,
                                    amount: Number(value),
                                  },
                                  editChanges: editChanges,
                                });
                              }
                            }}
                            value={this.state.price.amount}
                            className="form-control form-control-sm col-2 no-arrow-field"
                          />
                          <div className="mx-1" style={{ width: "10rem" }}>
                            <FSelect
                              disableFormGroup
                              value={this.state.price.type}
                              onChange={(e) => {
                                let price = this.state.price;
                                price.type = e.target.value;
                                let updatedState = { price };
                                if (
                                  price.type === "Per family" ||
                                  price.type === "Per member"
                                ) {
                                  updatedState.childPrice = {
                                    amount: 0,
                                    shipping: 0,
                                  };

                                  updatedState.includesChildPrice = false;
                                }

                                this.setState(updatedState);
                              }}
                              name="type"
                              dataList={priceTypes}
                              dataOnlyValue
                              tabIndex="1"
                              required
                              disabled={this.state.viewMode}
                              flex
                            />
                          </div>
                          <div className="col-md-2">
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                flexWrap: "nowrap",
                                alignItems: "center",
                                // width: "fit-content",
                              }}
                            >
                              <span className="mr-1">+ </span>
                              <input
                                prefix="$"
                                type="Number"
                                style={{ width: "5rem" }}
                                onChange={(e) => {
                                  let price = this.state.price;
                                  let editChanges = this.state.editChanges;
                                  editChanges.productId =
                                    this.state.stripeProductId;
                                  let value = e.target.value;
                                  if (Number(value) >= 0) {
                                    editChanges.priceChange = true;
                                    if (
                                      value.includes(".") &&
                                      value.length - value.indexOf(".") > 2
                                    ) {
                                      value = value.slice(
                                        0,
                                        value.indexOf(".") + 3
                                      );
                                    }
                                    this.setState({
                                      price: {
                                        ...price,
                                        shipping: Number(value),
                                      },
                                      editChanges: editChanges,
                                    });
                                  }
                                }}
                                value={this.state.price.shipping}
                                className="form-control form-control-sm col-8 no-arrow-field"
                              />
                              <span style={{ width: "" }} className="ml-1">
                                {" "}
                                Shipping
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.includesChildPrice ? (
                  <div className="row">
                    <div className="col-md-9">
                      <div
                        className="row mt-2"
                        style={{ position: "relative" }}
                      >
                        <div className="col-md-4"></div>

                        <div className="col-md-8">
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <input
                                prefix="$"
                                type="Number"
                                min={0}
                                onChange={(e) => {
                                  let childPrice = this.state.childPrice;
                                  let editChanges = this.state.editChanges;
                                  editChanges.childProductId =
                                    this.state.childPrice.childProductId;
                                  let value = e.target.value;
                                  if (Number(value) >= 0) {
                                    editChanges.childPriceChange = true;
                                    if (
                                      value.includes(".") &&
                                      value.length - value.indexOf(".") > 2
                                    ) {
                                      value = value.slice(
                                        0,
                                        value.indexOf(".") + 3
                                      );
                                    }
                                    this.setState({
                                      childPrice: {
                                        ...childPrice,
                                        amount: Number(value),
                                      },
                                      editChanges: editChanges,
                                    });
                                  }
                                }}
                                value={this.state.childPrice.amount}
                                className="form-control form-control-sm col-2 no-arrow-field"
                              />
                            </div>
                            <div className="mx-1 col-md-2">
                              <div className="row">
                                <input
                                  value={"Per child"}
                                  className="form-control form-control-sm "
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div
                                style={{
                                  flexWrap: "nowrap",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <span className="mr-2">+ </span>
                                <input
                                  prefix="$"
                                  style={{
                                    width: "3rem",
                                    marginLeft: "1rem",
                                    marginRight: "1rem",
                                  }}
                                  type="Number"
                                  onChange={(e) => {
                                    let childPrice = this.state.childPrice;
                                    let editChanges = this.state.editChanges;
                                    editChanges.childProductId =
                                      this.state.childPrice.childProductId;
                                    let value = e.target.value;
                                    if (Number(value) >= 0) {
                                      editChanges.childPriceChange = true;
                                      if (
                                        value.includes(".") &&
                                        value.length - value.indexOf(".") > 2
                                      ) {
                                        value = value.slice(
                                          0,
                                          value.indexOf(".") + 3
                                        );
                                      }
                                      this.setState({
                                        childPrice: {
                                          ...childPrice,
                                          shipping: Number(value),
                                        },
                                        editChanges: editChanges,
                                      });
                                    }
                                  }}
                                  value={this.state.childPrice.shipping}
                                  className="form-control form-control-sm  no-arrow-field"
                                />
                                <p
                                  style={{ width: "max-content", margin: "0" }}
                                  className="flex-center"
                                >
                                  {" "}
                                  Shipping
                                </p>
                              </div>
                            </div>
                            <div className="col-md-1">
                              <button
                                className="ant-btn"
                                type="button"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  bottom: 0,
                                  left: "35rem",
                                }}
                                onClick={() => {
                                  this.setState({
                                    childPrice: {
                                      amount: 0,
                                      shipping: 0,
                                    },
                                    includesChildPrice: false,
                                  });
                                }}
                              >
                                x
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <p>
                        Child package does not allow Chant access. Only child’s
                        name is collected. A member number is assigned. Details
                        are stored with parent account.
                      </p>
                    </div>
                  </div>
                ) : (
                  this.state.price.type !== "Per family" &&
                  this.state.price.type !== "Per member" && (
                    <div className="row">
                      <div className="col-md-3"></div>
                      <div className="col-md-9" style={{ padding: "0px" }}>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ includesChildPrice: true });
                          }}
                        >
                          + Add price per child
                        </span>
                      </div>
                    </div>
                  )
                )}
                <div className="row">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label>
                          Offer shipping<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <div
                          className="row align-items-center"
                          style={{ marginLeft: "-0.8rem" }}
                        >
                          <div className="col-md-8">
                            <div className="row">
                              <FSelect
                                disableFormGroup
                                value={this.state.offerShipping}
                                onChange={(e) => {
                                  this.setState({
                                    offerShipping: e.target.value,
                                  });
                                }}
                                name="type"
                                dataList={shippingOptions?.map(
                                  (item) => item.label
                                )}
                                placeholder="Select"
                                dataOnlyValue
                                tabIndex="1"
                                required
                                disabled={this.state.viewMode}
                                flex
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3"></div>
                          <label>Shipping note</label>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <FInput
                          value={this.state.shippingNote}
                          name="shippingNote"
                          onChange={(e) => {
                            this.setState({
                              shippingNote: e.target.value,
                            });
                          }}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3"></div>
                          <label>Pickup note</label>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <FInput
                          value={this.state.pickupNote}
                          name="pickupNote"
                          onChange={(e) => {
                            this.setState({
                              pickupNote: e.target.value,
                            });
                          }}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mt-2">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Merchandise</label>
                            <Tooltip
                              placement="topLeft"
                              title={() => (
                                <div style={{ width: 400 }}>
                                  If package includes merchandise (or choice of
                                  merchandise) select one or more items. Product
                                  must first be added in the 'Store'. Products
                                  requiring 'size' will use the size field
                                  associated with that product.
                                </div>
                              )}
                            >
                              <span className="border-0">
                                &nbsp;
                                <i className="fa fa-question-circle-o"></i>
                              </span>
                            </Tooltip>
                          </div>
                          <div className="col-md-8">
                            <FSelect
                              width="100%"
                              value={this.state.productChoice}
                              onChange={(e) => {
                                this.setState({
                                  productChoice: e.target.value,
                                });
                              }}
                              dataList={productChoices}
                              dataOnlyValue={true}
                            />
                          </div>
                        </div>
                        {this.state.productChoice === "None" ? (
                          <div className="row mt-2">
                            {/* <div className='col-md-4'>
                                <label>
                                    Ask t-shirt size?
                                </label>
                                <Tooltip placement="topLeft" title={() => <div style={{ width: 400 }}>Use only if package includes a t-shirt and you are NOT using the 'Offer Product Choice' option.</div>}>
                                    <span className="border-0">
                                        &nbsp;<i className="fa fa-question-circle-o"></i>
                                    </span>
                                </Tooltip>
                            </div>
                            <div className='col-md-8'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='row'>
                                            <input
                                                style={{ height: "20px", width: "20px" }}
                                                type="checkbox"
                                                className="form-control-input cursor-pointer"
                                                onChange={() => {
                                                    this.setState((state) => ({ askTshirtSize: !state.askTshirtSize }));
                                                }}
                                                checked={this.state.askTshirtSize}
                                            />
                                            {this.state.askTshirtSize && <span className='ml-2' style={{ textDecoration: 'underline' }} onClick={() => { this.setState({ showSizes: true }) }}>Select Available Sizes</span>}
                                        </div>
                                        {this.state.showSizes && <ul style={{ listStyleType: 'none' }}>
                                            {allTshirtSizes.map(size => <li>
                                                <div className='row'>
                                                    <input
                                                        style={{ height: "20px", width: "20px" }}
                                                        type="checkbox"
                                                        className="form-control-input cursor-pointer"
                                                        checked={this.state.tshirtSizes.includes(size)}
                                                        onChange={(e) => {
                                                            let tshirtSizes = this.state.tshirtSizes;
                                                            if (e.target.checked) {
                                                                tshirtSizes.push(size)
                                                            } else {
                                                                tshirtSizes.splice(tshirtSizes.indexOf(size), 1);
                                                            }
                                                            this.setState({ tshirtSizes });
                                                        }}
                                                    />
                                                    <span className='ml-2'>{size}</span>
                                                </div>
                                            </li>)}
                                        </ul>}
                                    </div>
                                </div>
                            </div> */}
                          </div>
                        ) : (
                          <div className="row mt-2">
                            <div className="col-md-4">
                              <div className="ml-5">Select options</div>
                            </div>
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-4"></div>
                                <ul
                                  style={{
                                    listStyleType: "none",
                                    paddingInlineStart: 0,
                                    marginLeft: "12px",
                                  }}
                                >
                                  {filteredStoreItems.map((item) => (
                                    <li>
                                      <div
                                        className="d-flex"
                                        style={{
                                          gap: "4px",
                                          marginBottom: "4px",
                                        }}
                                      >
                                        <input
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                          type="checkbox"
                                          onChange={(e) => {
                                            let products = this.state.products;
                                            if (products.includes(item.id)) {
                                              products.splice(
                                                products.indexOf(item.id),
                                                1
                                              );
                                            } else {
                                              products.push(item.id);
                                            }

                                            this.setState({ products });
                                          }}
                                          checked={this.state.products.includes(
                                            item.id
                                          )}
                                        />
                                        <span className="ml-2">
                                          {item.name}
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <h6 style={{ color: "#ff0000", fontWeight: "bold" }}>
                          Important:
                        </h6>
                        <p>
                          Chant does not block members from purchasing a member
                          package based on inventory level (set in the Store) of
                          ‘included’ items.
                        </p>

                        <p>Please track inventory levels accordingly.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {Object.keys(this.state?.packageTimeline || {}).length > 0 && (
            <Collapse defaultActiveKey={["1"]} style={{ marginBottom: "16px" }}>
              <Panel header="Package History" key="1">
                <PackageHistory
                  timeline={this.state.packageTimeline}
                  groupCurrency={groupCurrency}
                  storeItems={this.state.storeItems}
                />
              </Panel>
            </Collapse>
          )}

          <div
            className="col"
            style={{ borderTop: "1px solid #dddddd", paddingTop: 24 }}
          >
            <div className="d-flex" style={{ justifyContent: "flex-end" }}>
              <div className="d-flex" style={{ gap: 20 }}>
                <Button
                  onClick={() => {
                    this.props.close();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    this.savePackage();
                  }}
                  loading={this.state.saving}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  let adminData = state.adminData.allGroups[currentGroup];
  let { groupName } = state.adminData.allGroups[currentGroup].data;
  return {
    currentGroup,
    adminData,
    groupName,
  };
};
export default connect(mapStateToProps)(MemberPackageForm);
