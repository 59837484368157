// 3rd party design libraries
import { Alert, Modal } from "antd";
// 3rd party helper libraries
import React from "react";
import { connect } from "react-redux";

import Map from "../../commons/formFields/Map";
// common components
import LoadingModal from "../../commons/LoadingModal";
// import { geolocated } from "react-geolocated";

import CreateContentForm from "./CreateContentForm";
import { withRouter } from "../../../utils/helper";

class CreateContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      locationData: null,
      loadingDocument: !!props.router.params.id,
      loadingMatches: true,
      isLoading: false,
      errorInForm: {
        isError: false,
        visible: false,
        message: "",
      },
    };
  }

  handleChildStateUpdate = (stateName, value) => {
    this.setState({ [stateName]: value });
  };

  render() {
    let loadingTitle = "";
    let loadingMessage = "";

    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <div className="col">
        {this.state.showModal ? (
          <div>
            <Modal
              open={this.state.showModal}
              // closable={true}
              width="150vh"
              onOk={this.handleMapOK}
              onCancel={this.handleModalChange}
              // onClose={this.handleModalChange}
            >
              {
                <Map
                  onRef={(ref) => (this.child = ref)}
                  mapUpdate={this.mapUpdate}
                  lat={this.state.locationData.latitude}
                  long={this.state.locationData.longitude}
                  place_id={this.state.locationData.placeId}
                />
              }
            </Modal>
          </div>
        ) : (
          <div></div>
        )}
        <LoadingModal
          title={loadingTitle}
          message={loadingMessage}
          // percentage={this.state.loadingPercentage}
          loading={
            this.state.loadingDocument ||
            this.state.loadingMatches ||
            this.state.isLoading
          }
        />
        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              // className="mb-3"
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}
        <div className="md-5 border-box" style={{ padding: "30px 16px" }}>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "black",
              }}
            >
              <a>Create News/Event</a>
            </li>
          </ul>
          <CreateContentForm
            isLoading={this.state.isLoading}
            loadingMatches={this.state.loadingMatches}
            loadingDocument={this.state.loadingDocument}
            showModal={this.state.showModal}
            errorInForm={this.state.errorInForm}
            handleChildStateUpdate={this.handleChildStateUpdate.bind(this)}
          />
        </div>
      </div>
    );
  }

  // ? Life cycle methods and helper functions
}
const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  let { clubId } = state.adminData.allGroups[currentGroup].data;
  return {
    user: state.user,
    currentGroup,
    clubId,
    adminData: state.adminData.allGroups[currentGroup],
  };
};

// export default geolocated({
//   positionOptions: { enableHighAccuracy: true },
//   // userDecisionTimeout: 5000
// })(connect(mapStateToProps)(CreateContent));

export default connect(mapStateToProps)(withRouter(CreateContent));
