import React from "react";
import * as Qrcode from "qrcode";

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function getHeader(color, scale, style) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={758.496 * scale}
      height={344.907 * scale}
      viewBox="0 0 758.496 344.907"
      style={style}
    >
      <rect
        id="Rectangle_7"
        data-name="Rectangle 7"
        width={622.248 * scale}
        height={220.13 * scale}
        transform="translate(676.072 344.907) rotate(180)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_229"
        data-name="Path 229"
        d="M315.388,62.327,450.94-162.434V-282.307L234.746,62.327Z"
        transform="translate(58.006 282.307)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_230"
        data-name="Path 230"
        d="M564.938,62.327,700.49-162.434V-282.307L484.3,62.327Z"
        transform="translate(58.006 282.307)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_231"
        data-name="Path 231"
        d="M108.607,62.327,244.159-162.434V-282.307L27.966,62.327Z"
        transform="translate(58.006 282.307)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_232"
        data-name="Path 232"
        d="M255.724,62.327,391.276-162.434V-282.307L175.082,62.327Z"
        transform="translate(58.006 282.307)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_233"
        data-name="Path 233"
        d="M494.38,62.327,629.931-162.434V-282.307L413.738,62.327Z"
        transform="translate(58.006 282.307)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.65"
      />
      <path
        id="Path_234"
        data-name="Path 234"
        d="M184.8,62.327,323.352-162.434l-60-119.873L44.159,62.327Z"
        transform="translate(58.006 282.307)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_235"
        data-name="Path 235"
        d="M438.985,62.327,577.537-162.434l-60-119.873L298.343,62.327Z"
        transform="translate(58.006 282.307)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_236"
        data-name="Path 236"
        d="M82.636,62.327,221.188-162.434l-60-119.873L-58.006,62.327Z"
        transform="translate(58.006 282.307)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
    </svg>
  );
}

export function getBorder(color, scale = 1) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={210.063 * scale}
      height={220.835 * scale}
      viewBox="0 0 210.063 220.835"
    >
      <g
        id="Group_9"
        data-name="Group 9"
        transform="translate(-200.969 -341.192)"
      >
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="177.063"
          height="188.01"
          rx="9.28"
          transform="translate(217.469 352.051)"
          fill="none"
          stroke={rgbToHex(color.r, color.g, color.b)}
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          width="193.904"
          height="193.904"
          transform="translate(209.048 341.192)"
          fill="none"
        />
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="210.063"
          height="35.01"
          rx="9.28"
          transform="translate(200.969 527.016)"
          fill={rgbToHex(color.r, color.g, color.b)}
        />
        <g id="Group_8" data-name="Group 8">
          <path
            id="Path_206"
            data-name="Path 206"
            d="M224.039,531.355h13.092a1.476,1.476,0,0,1,1.309,1.585V556.7a1.476,1.476,0,0,1-1.309,1.585H224.039a1.476,1.476,0,0,1-1.309-1.585V532.94A1.476,1.476,0,0,1,224.039,531.355Zm5.76,1.321h1.571v.528H229.8Zm-3.4,22.442h8.378V556.7H226.4Zm-2.619-20.594h13.615v19.01H223.777Z"
            fill="#efefef"
            fill-rule="evenodd"
          />
          <g id="Group_7" data-name="Group 7">
            <path
              id="Path_207"
              data-name="Path 207"
              d="M248.351,547.741a5.049,5.049,0,0,0,3.772,1.689c1.939,0,2.622-.989,2.622-1.85,0-1.258-1.365-1.616-2.891-2.012-1.922-.5-4.149-1.059-4.149-3.5,0-1.975,1.742-3.412,4.238-3.412a5.869,5.869,0,0,1,4.364,1.67l-1.041,1.329a4.677,4.677,0,0,0-3.467-1.4c-1.329,0-2.244.683-2.244,1.688,0,1.078,1.293,1.419,2.783,1.8,1.958.521,4.239,1.132,4.239,3.682,0,1.868-1.293,3.61-4.526,3.61a6.126,6.126,0,0,1-4.723-1.9Z"
              fill="#fff"
            />
            <path
              id="Path_208"
              data-name="Path 208"
              d="M262.5,541.923a3.785,3.785,0,0,1,3.25,1.526l-1.077.988a2.421,2.421,0,0,0-2.1-1.078,3.134,3.134,0,0,0,0,6.232,2.465,2.465,0,0,0,2.1-1.077l1.077.987a3.785,3.785,0,0,1-3.25,1.527,4.555,4.555,0,0,1,0-9.105Z"
              fill="#fff"
            />
            <path
              id="Path_209"
              data-name="Path 209"
              d="M272.877,549.86a3.715,3.715,0,0,1-2.837,1.168,2.883,2.883,0,0,1-3.018-2.874,2.8,2.8,0,0,1,3.018-2.838,3.618,3.618,0,0,1,2.837,1.15v-1.437c0-1.077-.879-1.723-2.119-1.723a3.551,3.551,0,0,0-2.6,1.149l-.7-1.114a4.878,4.878,0,0,1,3.556-1.418c1.886,0,3.484.826,3.484,3.035v5.855h-1.617Zm0-2.423a2.746,2.746,0,0,0-2.227-.989,1.742,1.742,0,1,0,0,3.448,2.744,2.744,0,0,0,2.227-.987Z"
              fill="#fff"
            />
            <path
              id="Path_210"
              data-name="Path 210"
              d="M282.932,545.263c0-1.437-.736-1.9-1.85-1.9a3.1,3.1,0,0,0-2.388,1.257v6.2h-1.617v-8.675h1.617v1.221a4.137,4.137,0,0,1,3.071-1.436,2.492,2.492,0,0,1,2.783,2.8v6.088h-1.616Z"
              fill="#fff"
            />
            <path
              id="Path_211"
              data-name="Path 211"
              d="M292.069,548.891v-5.334h-1.437v-1.419h1.437v-2.37h1.616v2.37h1.761v1.419h-1.761v4.974c0,.612.288,1.06.845,1.06a1.242,1.242,0,0,0,.88-.341l.413,1.222a2.307,2.307,0,0,1-1.689.556A1.887,1.887,0,0,1,292.069,548.891Z"
              fill="#fff"
            />
            <path
              id="Path_212"
              data-name="Path 212"
              d="M296.7,546.466a4.386,4.386,0,1,1,4.382,4.562A4.305,4.305,0,0,1,296.7,546.466Zm7.076,0c0-1.634-.951-3.107-2.694-3.107a2.82,2.82,0,0,0-2.694,3.107c0,1.652.97,3.125,2.694,3.125C302.826,549.591,303.777,548.118,303.777,546.466Z"
              fill="#fff"
            />
            <path
              id="Path_213"
              data-name="Path 213"
              d="M318.319,549.573a3.582,3.582,0,0,1-2.837,1.455c-2.262,0-3.879-1.706-3.879-4.544,0-2.784,1.617-4.561,3.879-4.561a3.528,3.528,0,0,1,2.837,1.473v-4.563h1.618v11.98h-1.618Zm0-4.938a2.994,2.994,0,0,0-2.406-1.276c-1.616,0-2.622,1.311-2.622,3.125,0,1.8,1.006,3.107,2.622,3.107a2.98,2.98,0,0,0,2.406-1.257Z"
              fill="#fff"
            />
            <path
              id="Path_214"
              data-name="Path 214"
              d="M321.979,546.466a4.386,4.386,0,1,1,4.383,4.562A4.306,4.306,0,0,1,321.979,546.466Zm7.077,0c0-1.634-.952-3.107-2.694-3.107a2.82,2.82,0,0,0-2.694,3.107c0,1.652.97,3.125,2.694,3.125C328.1,549.591,329.056,548.118,329.056,546.466Z"
              fill="#fff"
            />
            <path
              id="Path_215"
              data-name="Path 215"
              d="M338.176,544.149l-2.137,6.664h-1.671l-2.729-8.675h1.688l1.976,6.609,2.173-6.609h1.4l2.173,6.609,1.975-6.609h1.689l-2.731,8.675h-1.67Z"
              fill="#fff"
            />
            <path
              id="Path_216"
              data-name="Path 216"
              d="M352,545.263c0-1.437-.736-1.9-1.85-1.9a3.1,3.1,0,0,0-2.388,1.257v6.2h-1.616v-8.675h1.616v1.221a4.137,4.137,0,0,1,3.071-1.436,2.493,2.493,0,0,1,2.784,2.8v6.088H352Z"
              fill="#fff"
            />
            <path
              id="Path_217"
              data-name="Path 217"
              d="M356.182,538.833H357.8v11.98h-1.616Z"
              fill="#fff"
            />
            <path
              id="Path_218"
              data-name="Path 218"
              d="M359.825,546.466a4.386,4.386,0,1,1,4.383,4.562A4.306,4.306,0,0,1,359.825,546.466Zm7.076,0c0-1.634-.951-3.107-2.693-3.107a2.819,2.819,0,0,0-2.694,3.107c0,1.652.969,3.125,2.694,3.125C365.95,549.591,366.9,548.118,366.9,546.466Z"
              fill="#fff"
            />
            <path
              id="Path_219"
              data-name="Path 219"
              d="M375.986,549.86a3.717,3.717,0,0,1-2.838,1.168,2.882,2.882,0,0,1-3.017-2.874,2.8,2.8,0,0,1,3.017-2.838,3.621,3.621,0,0,1,2.838,1.15v-1.437c0-1.077-.881-1.723-2.12-1.723a3.548,3.548,0,0,0-2.6,1.149l-.7-1.114a4.879,4.879,0,0,1,3.556-1.418c1.886,0,3.485.826,3.485,3.035v5.855h-1.617Zm0-2.423a2.747,2.747,0,0,0-2.227-.989,1.742,1.742,0,1,0,0,3.448,2.746,2.746,0,0,0,2.227-.987Z"
              fill="#fff"
            />
            <path
              id="Path_220"
              data-name="Path 220"
              d="M386.364,549.573a3.586,3.586,0,0,1-2.838,1.455c-2.262,0-3.88-1.706-3.88-4.544,0-2.784,1.618-4.561,3.88-4.561a3.531,3.531,0,0,1,2.838,1.473v-4.563h1.616v11.98h-1.616Zm0-4.938a3,3,0,0,0-2.407-1.276c-1.616,0-2.622,1.311-2.622,3.125,0,1.8,1.006,3.107,2.622,3.107a2.984,2.984,0,0,0,2.407-1.257Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function getFooter(color, scale = 1, style) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={778.495 * scale}
      height={344.907 * scale}
      viewBox="0 0 778.495 344.907"
      style={style}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_9"
            data-name="Rectangle 9"
            width="123.997"
            height="36.517"
            rx="6.435"
            transform="translate(446.298 733.398)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_11"
            data-name="Rectangle 11"
            width="123.997"
            height="36.517"
            rx="6.435"
            transform="translate(308.989 733.398)"
            fill="none"
          />
        </clipPath>
      </defs>
      <rect
        id="Rectangle_6"
        data-name="Rectangle 6"
        width={622.248 * scale}
        height={220.13 * scale}
        transform={`translate(${82.423 * scale})`}
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.6"
      />
      <path
        id="Path_221"
        data-name="Path 221"
        d="M299.856,648.838,164.3,873.6V993.472L380.5,648.838Z"
        transform="translate(85.246 -648.565)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_222"
        data-name="Path 222"
        d="M50.306,648.838-85.246,873.6V993.472L130.947,648.838Z"
        transform="translate(85.246 -648.565)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_223"
        data-name="Path 223"
        d="M506.636,648.838,371.084,873.6V993.472L587.278,648.838Z"
        transform="translate(85.246 -648.565)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_224"
        data-name="Path 224"
        d="M359.52,648.838,223.968,873.6V993.472L440.161,648.838Z"
        transform="translate(85.246 -648.565)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_225"
        data-name="Path 225"
        d="M120.864,648.838-14.688,873.6V993.472L201.506,648.838Z"
        transform="translate(85.246 -648.565)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.6"
      />
      <path
        id="Path_226"
        data-name="Path 226"
        d="M430.443,648.838,291.891,873.6l60,119.873L571.085,648.838Z"
        transform="translate(85.246 -648.565)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.5"
      />
      <path
        id="Path_227"
        data-name="Path 227"
        d="M176.259,648.838,37.707,873.6l60,119.873L316.9,648.838Z"
        transform="translate(85.246 -648.565)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.6"
      />
      <path
        id="Path_228"
        data-name="Path 228"
        d="M552.607,648.838,414.056,873.6l60,119.873L693.249,648.838Z"
        transform="translate(85.246 -648.565)"
        fill={rgbToHex(color.r, color.g, color.b)}
        opacity="0.6"
      />
      <rect
        id="Artwork_4"
        data-name="Artwork 4"
        width="206.762"
        height="87.586"
        transform="translate(114.606 34.036)"
        fill="none"
      />
      <g
        id="Group_12"
        data-name="Group 12"
        transform="translate(85.246 -648.565)"
      >
        <g id="Group_11" data-name="Group 11" clip-path="url(#clip-path)">
          <rect
            id="Rectangle_8"
            data-name="Rectangle 8"
            width="138.097"
            height="92.064"
            transform="translate(439.4 727.5)"
            fill="none"
          />
        </g>
      </g>
      <g
        id="Group_14"
        data-name="Group 14"
        transform="translate(85.246 -648.565)"
      >
        <g id="Group_13" data-name="Group 13" clip-path="url(#clip-path-2)">
          <rect
            id="Rectangle_10"
            data-name="Rectangle 10"
            width="138.097"
            height="92.064"
            transform="translate(302.091 683.789)"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
}

export const generateQR = (url, logo, SIZE) => {
  return Qrcode.toString(url || "https://reactjs.org/").then((svg) => {
    let groupLogo = logo;
    if ((groupLogo || "").includes("&token")) {
      groupLogo = groupLogo.split("&")[0];
    }
    let requiredSVG = svg;
    let svgTagArray = requiredSVG.replace(/></g, ">|<").split("|");
    let viewBoxDetails = svgTagArray[0].slice(
      svgTagArray[0].indexOf("viewBox") + 9,
      svgTagArray[0].indexOf('"', svgTagArray[0].indexOf("viewBox") + 9)
    );
    let [x, y, w, h] = viewBoxDetails
      .split(" ")
      .map((elem) => parseFloat(elem));
    let firstElemAsArr = svgTagArray[0].split("");
    firstElemAsArr.splice(
      svgTagArray[0].length - 1,
      1,
      ` height="${SIZE}" width="${SIZE}"><defs>
        <clipPath id="clip">
           <circle cx="${x + w / 2}" cy="${y + h / 2}" r="${w / 6}" fill="#000000" />
        </clipPath>
     </defs>`
    );
    svgTagArray[0] = firstElemAsArr.join("");
    // svgTagArray[svgTagArray.length - 1] = `<rect x="${x+w*0.4}" y="${y+h*0.4}" height="${h*0.2}" width="${w*0.2}" fill="#ffffff"/></svg>`
    requiredSVG = svgTagArray.join("");
    return requiredSVG;
  });
};

export class CssManipulator {
  constructor() {
    var len = document.styleSheets.length, // Caches the length of the collection
      n; // General loop counter
    let head = document.head;
    let style = document.createElement("style");
    head.appendChild(style);
    style.title = "main";

    this.styleSheet = style.sheet; // Stores the stylesheet for the instance
    this.selectors = {}; // Stores the selectors we've handled
    this.cssRules = null; // Caches cssRules of the given stylesheet

    // Search the given stylesheet by title and assign it and its cssRules to instance properties
    for (n = 0; n < len; n++) {
      if (document.styleSheets[n].title === "main") {
        this.styleSheet = document.styleSheets[n];
        this.cssRules =
          document.styleSheets[n].cssRules || document.styleSheets[n].rules;
        break;
      }
    }
    // Changes properties of the given selector
    this.change = (selector, prop, value) => {
      var propName =
        prop.charAt(0) === "-" ? prop.substring(1, prop.length) : prop;
      propName = propName.replace(/-([a-z])/gi, function (str, chr) {
        return chr.toUpperCase();
      });
      if (this.styleSheet) {
        if (selector in this.selectors) {
          // Change the rule, if we've handled this selector before
          this.styleSheet.cssRules[this.selectors[selector]].style[propName] =
            value;
        } else {
          // Add a new rule if we haven't met this selector before
          this.selectors[selector] = this.styleSheet.insertRule(
            selector + "{" + prop + ":" + value + "!important;}",
            this.cssRules.length
          );
        }
      }
    };
  }
}

export function switchCollapseHorizontal(id) {
  let elem = document.getElementById(id);
  if (!elem.className.includes("responsive")) {
    elem.className += " responsive";
  } else {
    let classNames = elem.className.split(" ");
    classNames = classNames.filter((name) => name !== "responsive");
    elem.className = classNames.join(" ");
  }
}

const currencyMap = {
  ALL: "Lek",
  AED: "AED",
  AFN: "؋",
  ARS: "$",
  AWG: "ƒ",
  AUD: "$",
  AZN: "₼",
  BSD: "$",
  BBD: "$",
  BYN: "Br",
  BZD: "BZ$",
  BMD: "$",
  BOB: "$b",
  BAM: "KM",
  BWP: "P",
  BGN: "лв",
  BRL: "R$",
  BND: "$",
  KHR: "៛",
  CAD: "$",
  KYD: "$",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  HRK: "kn",
  CUP: "₱",
  CZK: "Kč",
  DKK: "kr",
  DOP: "RD$",
  XCD: "$",
  EGP: "£",
  SVC: "$",
  EUR: "€",
  FKP: "£",
  FJD: "$",
  GHS: "¢",
  GIP: "£",
  GTQ: "Q",
  GGP: "£",
  GYD: "$",
  HNL: "L",
  HKD: "$",
  HUF: "Ft",
  ISK: "kr",
  INR: "₹",
  IDR: "Rp",
  IRR: "﷼",
  IMP: "£",
  ILS: "₪",
  JMD: "J$",
  JPY: "¥",
  JEP: "£",
  KZT: "лв",
  KPW: "₩",
  KRW: "₩",
  KGS: "лв",
  LAK: "₭",
  LBP: "£",
  LRD: "$",
  MKD: "ден",
  MYR: "RM",
  MUR: "₨",
  MXN: "$",
  MNT: "₮",
  MZN: "MT",
  NAD: "$",
  NPR: "₨",
  ANG: "ƒ",
  NZD: "$",
  NIO: "C$",
  NGN: "₦",
  NOK: "kr",
  OMR: "﷼",
  PKR: "₨",
  PAB: "B/.",
  PYG: "Gs",
  PEN: "S/.",
  PHP: "₱",
  PLN: "zł",
  QAR: "﷼",
  RON: "lei",
  RUB: "₽",
  SHP: "£",
  SAR: "﷼",
  RSD: "Дин",
  SCR: "₨",
  SGD: "$",
  SBD: "$",
  SOS: "S",
  ZAR: "R",
  LKR: "₨",
  SEK: "kr",
  CHF: "CHF",
  SRD: "$",
  SYP: "£",
  TWD: "NT$",
  THB: "฿",
  TTD: "TT$",
  TRY: "₺",
  TVD: "$",
  UAH: "₴",
  GBP: "£",
  USD: "$",
  UYU: "$U",
  UZS: "лв",
  VEF: "Bs",
  VND: "₫",
  YER: "﷼",
  ZWD: "Z$",
};

export function getCurrencyUnicode(currencyISO) {
  return currencyMap[currencyISO];
}

export function randomString() {
  let length = 10;
  var chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".split("");

  var str = "";
  for (var i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
}

export async function postUpdateCDNUtil(groupId, section) {
  let url = process.env.REACT_APP_CDN_URL;
  //https://us-central1-chant2019.cloudfunctions.net/checkCDN/updateGroupConfig?groupId=9Tq4VIWanIo64RhwdoEs&section=store

  if (groupId) {
    url += `?groupId=${groupId}`;
    if (section) {
      url += `&section=${section}`;
    }
    await fetch(url);
  }
}

export const getMinPackageSeasonFromTimeline = (packageTimeline) => {
  return packageTimeline
    ? Math.min(...Object.keys(packageTimeline).map((year) => Number(year)))
    : null;
};
