import { Button, Popconfirm, Table, Tag, Tooltip, message } from "antd";
import {
  setGettingStartedItemStatus,
  startAddMembers,
  startAddTickets,
  startDeleteTicket,
} from "../../../redux/actions/adminData";

import LoadingModal from "../../commons/LoadingModal";
import React from "react";
import Ticket from "../../../models/admin/Ticket";
import axios from "axios";
import { connect } from "react-redux";
import {
  fbDeleteTicket,
  fbGetTickets,
} from "../../../services/firebaseService/endPoints/admin/tickets";
import moment from "moment";
import {
  getCurrencyUnicode,
  switchCollapseHorizontal,
} from "../../../helperFunctions/util";
import {
  GUIDE_ENGAGE,
  TOPIC_SELL_TICKETS,
} from "../../../constants/gettingStarted";
import { isMobile, withRouter } from "../../../utils/helper";
import {
  DownloadOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

message.config({
  top: "10vh",
});

class ListTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "current",
      isLoading: true,
      pagination: {
        position: "top",
      },
      tableData: [],
      sorter: { field: "", order: "" },
      groupId: props.currentGroup,
    };
  }

  handleEditTicket = (data) => {
    localStorage.setItem(
      "contentConfiguration",
      JSON.stringify({
        tab: this.state.tab,
        pagination: this.state.pagination,
        tableData: this.state.tableData,
        sorter: this.state.sorter,
      })
    );

    this.props.router.navigate(
      `/admin/tickets/edit/${this.props.currentGroup}/${data.id}`
    );
  };

  handleViewTicket = (data) => {
    localStorage.setItem(
      "ticketConfiguration",
      JSON.stringify({
        tab: this.state.tab,
        pagination: this.state.pagination,
        tableData: this.state.tableData,
        sorter: this.state.sorter,
      })
    );

    this.props.router.navigate(
      `/admin/tickets/${this.props.currentGroup}/${data.id}`
    );
  };

  handleDeleteTicket = (data) => {
    let confirm = window.confirm("Are you sure?");
    if (confirm) {
      const msg = message.loading("Deleting group ticket", 0);
      fbDeleteTicket(data.id, !!data.details.image, this.props.currentGroup)
        .then(() => {
          msg();
          this.props.dispatch(
            startDeleteTicket(this.state.tab, this.props.currentGroup, data.id)
          );
          message.success("Successfully deleted group ticket");
          this.setState({ isLoading: true });
        })
        .catch((err) => {
          msg();
          message.error(err.message);
        });
    }
  };

  changeTab = (e) => {
    this.setState(() => ({
      tab: e.target.name,
      isLoading: true,
      pagination: { current: 1, position: "top" },
      tableData: [],
    }));
  };

  handleTableChange = (pagination, filters, sorter) => {
    filters = { ...this.state.filters, ...filters };

    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sorter,
      filters,
    });
    this.fetchData();
    // document.querySelector(".ant-table-body").scrollTo(0, 0);
  };

  handleTicketRefresh = () => {
    this.setState({ isLoading: true });
    this.fetchData();
  };

  changeHeaderColor = () => {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = primaryColor;
    });
  };

  updatePublishStatus = async (id, status) => {
    this.setState({
      isLoading: true,
    });
    let doc = await fbGetTickets(id, this.props.currentGroup);

    let data = doc.data.body.data;

    let ticketData = new Ticket();
    ticketData.fromDataSet(data);

    let updateData = new Ticket();
    updateData.toDataSet(ticketData, status);
    let obj = {
      groupTicket: updateData.groupTicket,
      match: updateData.match,
      ticketId: id,
      groupId: this.props.currentGroup,
      oldTicketsCount: ticketData.oldTicketsCount,
    };

    axios({
      method: "post",
      url: "https://us-central1-chant2019.cloudfunctions.net/editSingleGroupTicket",
      data: obj,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async () => {
        await this.fetchData();
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    const columns = [
      {
        align: "center",
        title: "Action",
        width: 200,
        className: "header-white",
        render: (data) => {
          if (data.loading) {
            return {
              children: <></>,
              colSpan: 3,
            };
          }
          return (
            <div className="flex-center" style={{ gap: "6px" }}>
              {this.state.tab === "current" && (
                <>
                  &nbsp;&nbsp;
                  <Tooltip title="edit">
                    <i
                      className="fa fa-pencil cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        this.handleEditTicket(data);
                      }}
                    />
                  </Tooltip>
                  &nbsp;|&nbsp;
                </>
              )}

              <Tooltip title="view">
                <i
                  className="fa fa-eye cursor-pointer"
                  aria-hidden="true"
                  onClick={() => {
                    this.handleViewTicket(data);
                  }}
                />
              </Tooltip>
              {data.groupTicket.publishStatus === "live" && (
                <Popconfirm
                  title="Are you sure? Tickets will no longer appear."
                  onCancel={() => {}}
                  onConfirm={() =>
                    this.updatePublishStatus(data.id, "archived")
                  }
                >
                  <button className="btn btn-outline-danger ml-2">
                    Remove
                  </button>
                </Popconfirm>
              )}
              {data.groupTicket.publishStatus === "saved" && (
                <Popconfirm
                  title="Are you sure? Notification will be sent."
                  onCancel={() => {}}
                  onConfirm={() => {
                    this.updatePublishStatus(data.id, "live");
                  }}
                >
                  <button className="btn btn-outline-danger ml-2">
                    Post Live
                  </button>
                </Popconfirm>
              )}
            </div>
          );
        },
        // fixed: "left"
      },
      {
        align: "center",
        title: "Status",
        width: 100,
        className: "header-white",
        key: "publishStatus",
        dataIndex: "groupTicket",
        render: (data) => {
          const status = data.publishStatus;

          if (status === "live") {
            return <span>Live</span>;
          }
          if (status === "saved") {
            return <span>Saved</span>;
          }
          if (status === "archived") {
            return <span>Archived</span>;
          }
          if (status === "sale-ended") {
            return <span>Sale Ended</span>;
          }
          return <span></span>;
        },
      },
      {
        align: "center",
        title: "Web",
        width: 100,
        className: "header-white",
        key: "link",
        dataIndex: "link",
        render: (data, record) =>
          record.groupTicket.showOnWeb ? (
            <a href={data} target="_blank" rel="noopener noreferrer">
              Link
            </a>
          ) : (
            ""
          ),
      },
      {
        align: "center",
        title: "Match",
        width: window.screen.width > 500 ? 230 : 150,
        key: "matchName",
        // fixed: window.screen.width > 500 ? "left" : false,
        className: "header-white",
        dataIndex: "matchName",
        sorter: (a, b) => a.matchName.localeCompare(b.matchName),
        sortDirections: ["ascend", "descend"],
      },

      {
        align: "center",
        title: "Type",
        width: window.screen.width > 500 ? 160 : 100,
        key: "groupTicket.ticketType",
        // fixed: "left",
        dataIndex: "groupTicket",
        className: "header-white",
        render: (data) => {
          return data.ticketType;
        },
      },
      {
        title: window.screen.width > 500 ? "Event Name" : "Name",
        width: window.screen.width > 500 ? 150 : 100,
        key: "groupTicket.eventName",
        className: "header-white",
        align: "center",

        // dataIndex: "groupTicket.eventName",
        render: (record) =>
          record.groupTicket.ticketType === "Group Match"
            ? record.groupTicket.ticketDetail.section
            : record.groupTicket.eventName,
        sorter: (a, b) => {
          if (a.groupTicket.eventName > b.groupTicket.eventName) {
            return -1;
          }
          if (a.groupTicket.eventName < b.groupTicket.eventName) {
            return 1;
          }
          return 0;
        },
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Revenue",
        width: window.screen.width > 500 ? 160 : 100,
        key: "revenue",
        dataIndex: "groupTicket",
        className: "header-white",
        align: "center",
        render: (data) => {
          let dataAsFloat = parseFloat(
            (data?.collectedAmount || "0").toString()
          );
          let dataAsString = dataAsFloat.toFixed(
            Math.round(dataAsFloat) === dataAsFloat ? 0 : 2
          );
          return (
            <span>
              {data?.collectedAmount ? `${groupCurrency}${dataAsString}` : "-"}
            </span>
          );
        },
      },
      {
        title: (
          <div
            style={{ textAlign: "center" }}
            className="main-tickets-table-quantity-column"
          >
            <div className="main-tickets-table-quantity-column-content">
              <p style={{ fontSize: "18px" }}>Quantity</p>
              <p style={{ fontSize: "12px" }}>Total/Sold/Avail</p>
            </div>
          </div>
        ),
        width: window.screen.width > 500 ? 160 : 100,
        key: "quantity",
        dataIndex: "groupTicket.quantity",
        align: "center",
        className: "header-white",
        render: (data, record) => {
          let totalQuantity = 0;
          let totalSoldTickets = 0;

          if (record && record?.groupTicket?.tickets?.length > 0) {
            record.groupTicket.tickets.forEach((ticket) => {
              totalQuantity = totalQuantity + parseInt(ticket.quantity);
              totalSoldTickets = totalSoldTickets + ticket.soldTickets;
            });
          }

          return `${totalQuantity} / ${totalSoldTickets} / ${
            totalQuantity - totalSoldTickets
          }`;
        },
      },
      {
        title: "Checkins",
        width: window.screen.width > 500 ? 160 : 100,
        key: "checkins",
        dataIndex: "checkins",
        align: "center",
        className: "header-white",
        render: (data, record) => {
          return record?.groupTicket?.tickets?.reduce((acc, curr) => {
            return (acc += curr.noOfCheckins);
          }, 0);
        },
      },

      {
        title: (
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "18px" }}>Price</p>
            <p style={{ fontSize: "12px" }}>Member/Non-Member</p>
          </div>
        ),
        width: window.screen.width > 500 ? 160 : 100,
        align: "center",
        className: "header-white",
        key: "price",
        // dataIndex: "groupTicket.ticketDetail.price",
        // sorter: (a, b) =>
        //   a.groupTicket.ticketDetail.price - b.groupTicket.ticketDetail.price,
        // sortDirections: ["ascend", "descend"],
      },
      {
        align: "center",
        title: window.screen.width > 500 ? "Limit / Person?" : "Limit",
        key: "ticketLimitPerMember",
        className: "header-white",
      },
      {
        align: "center",
        title: window.screen.width > 500 ? "Event Date" : "Date",
        width: 150,
        key: "groupTicket",
        className: "header-white",
        dataIndex: "groupTicket",
        sorter: (a, b) =>
          moment(a.groupTicket.eventDate).unix() -
          moment(b.groupTicket.eventDate).unix(),
        sortDirections: ["ascend", "descend"],
        render: (groupTicket) => {
          let startDate = "";
          if (groupTicket.eventDate) {
            startDate = moment.unix(groupTicket.eventDate);
            startDate = startDate.isValid()
              ? startDate.format("Do MMM, YYYY")
              : "";
          }
          return <span>{startDate}</span>;
        },
      },
      {
        title: "Sale End Date",
        width: window.screen.width > 500 ? 160 : 100,
        key: "saleEndDate",
        className: "header-white",
        align: "center",
        dataIndex: "groupTicket",
        render: (data) => {
          return dayjs.unix(data.saleEndDate).format("Do MMM, YYYY");
        },
      },
      {
        align: "center",
        title: window.screen.width > 500 ? "Event Code" : "Code",
        width: 100,
        key: "eventCode",
        className: "header-white",
        dataIndex: "groupTicket",
        render: (data) => {
          return data.eventCode;
        },
        sortDirections: ["ascend", "descend"],
      },
    ];
    if (this.props.user.isAuthenticated === undefined) {
      return <LoadingModal />;
    }
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    const ticketsExpandedRow = (record) => {
      const columns = [
        {
          title: "Action",
          width: 200,
          className: "header-white",
          render: () => {
            return <></>;
          },
          align: "center",
        },
        {
          title: "Status",
          width: 100,
          className: "header-white",
          key: "status",
          dataIndex: "status",
          align: "center",
          render: (status) => {
            if (status === "live") {
              return <span>Live</span>;
            }
            if (status === "saved") {
              return <span>Saved</span>;
            }
            if (status === "archived") {
              return <span>Archived</span>;
            }
            if (status === "sale-ended") {
              return <span>Sale Ended</span>;
            }
            return <span></span>;
          },
        },
        {
          title: "Web",
          width: 100,
          className: "header-white",
          key: "link",
          align: "center",
        },
        {
          title: "Match",
          width: window.screen.width > 500 ? 230 : 150,
          key: "matchName",
          // fixed: window.screen.width > 500 ? "left" : false,
          className: "header-white",
          dataIndex: "matchName",
          align: "center",
        },
        {
          title: "Type",
          width: window.screen.width > 500 ? 160 : 100,
          key: "ticketType",
          dataIndex: "ticketType",
          className: "header-white",
          align: "center",
        },
        {
          title: window.screen.width > 500 ? "Event Name" : "Name",
          width: window.screen.width > 500 ? 150 : 100,
          key: "eventName",
          className: "header-white",
          dataIndex: "eventName",
          align: "center",
        },
        {
          title: "Revenue",
          width: window.screen.width > 500 ? 160 : 100,
          key: "revenue",
          dataIndex: "revenue",
          className: "header-white",
          align: "center",
          render: (data) => {
            let dataAsFloat = parseFloat((data || "0").toString());
            let dataAsString = dataAsFloat.toFixed(
              Math.round(dataAsFloat) === dataAsFloat ? 0 : 2
            );
            return (
              <span>{data ? `${groupCurrency}${dataAsString}` : "-"}</span>
            );
          },
        },
        {
          title: (
            <div style={{ textAlign: "center" }}>
              <p style={{ fontSize: "18px" }}>Quantity</p>
              <p style={{ fontSize: "12px" }}>Total/Sold/Avail</p>
            </div>
          ),
          width: window.screen.width > 500 ? 160 : 100,
          key: "quanity",
          dataIndex: "quantity",
          align: "center",
        },
        {
          title: "Checkins",
          width: window.screen.width > 500 ? 160 : 100,
          key: "checkins",
          dataIndex: "checkins",
          align: "center",
        },
        {
          title: (
            <div style={{ textAlign: "center" }}>
              <p style={{ fontSize: "18px" }}>Price</p>
              <p style={{ fontSize: "12px" }}>Member/Non-Member</p>
            </div>
          ),
          width: window.screen.width > 500 ? 160 : 100,
          key: "price",
          dataIndex: "price",
          align: "center",
        },

        {
          title: window.screen.width > 500 ? "Limit / Person?" : "Limit",
          // width: 100,
          key: "ticketLimitPerMember",
          className: "header-white",
          dataIndex: "ticketLimitPerMember",
          align: "center",
        },

        {
          title: window.screen.width > 500 ? "Event Date" : "Date",
          width: 150,
          key: "groupTicket",
          className: "header-white",
          render: () => <></>,
          align: "center",
        },
        {
          title: "Sale End Date",
          width: window.screen.width > 500 ? 160 : 100,
          key: "saleEndDate",
          align: "center",
        },
        {
          title: window.screen.width > 500 ? "Event Code" : "Code",
          width: 100,
          key: "groupTicket.eventCode",
          className: "header-white",
          align: "center",
        },
      ];

      const nestedTable = record?.groupTicket?.tickets?.map((item) => {
        return {
          showForFans: true,
          link: "",
          ticketType: item.matchTicket,
          eventName: item.name,
          revenue: item.revenue,
          quantity: `${item.quantity} / ${item.soldTickets} / ${
            item.quantity - item.soldTickets
          }`,
          checkins: item.noOfCheckins,
          price: `${item.memberPrice ? `$${item.memberPrice}` : "-"} / ${item.nonMemberPrice ? `$${item.nonMemberPrice}` : "-"}`,
          ticketLimitPerMember: item.limitPerPerson,
        };
      });

      return (
        <Table
          showHeader={false}
          columns={columns}
          dataSource={nestedTable}
          pagination={false}
          bodyStyle={{
            fontSize: window.screen.width > 500 ? "18px" : "12px",
          }}
          className="table-backdrop"
        />
      );
    };

    return (
      <div
        className="mx-auto col"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            fontSize: "15px",
            display: "flex",
            flexDirection: "column",
            padding: "30px 15px",
          }}
        >
          <div className="">
            <ul
              className="nav nav-tabs "
              style={{ color: "#ffffff", position: "relative" }}
            >
              {this.props.router.params.tab === "all" && (
                <>
                  <li
                    className="nav-item"
                    onClick={this.changeTab}
                    style={
                      this.state.tab === "current"
                        ? {
                            borderBottom: `4px solid ${primaryColor}`,
                          }
                        : {}
                    }
                  >
                    <a
                      name="current"
                      style={{
                        height: "100%",
                        fontWeight: "bold",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Current
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={this.changeTab}
                    style={
                      this.state.tab === "past"
                        ? {
                            borderBottom: `4px solid ${primaryColor}`,
                            marginLeft: 24,
                          }
                        : {
                            marginLeft: 24,
                          }
                    }
                  >
                    <a
                      name="past"
                      style={{
                        height: "100%",
                        fontWeight: "bold",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Past
                    </a>
                  </li>
                </>
              )}
              {this.props.router.params.tab === "update" && (
                <>
                  <li
                    className="nav-item"
                    onClick={this.changeTab}
                    style={{
                      borderBottom: `4px solid ${primaryColor}`,
                    }}
                  >
                    <a
                      name="update"
                      style={{
                        height: "100%",
                        fontWeight: "bold",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Update
                    </a>
                  </li>
                </>
              )}

              {this.props.router.params.tab === "all" && (
                <li
                  className="ml-auto align-items-center collapsible-horizontal tab-menu"
                  id="ticket-utilities"
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <div
                    className="d-flex justify-content-end"
                    style={{ gap: "6px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-info mr-1"
                      style={{
                        backgroundColor: primaryColor,
                        borderColor: primaryColor,
                        color: "var(--primary-text-color)",
                      }}
                      onClick={() => {
                        this.props.router.navigate("/admin/tickets/create");
                      }}
                    >
                      {isMobile() ? (
                        <PlusOutlined style={{ fontSize: "20px" }} />
                      ) : (
                        "Create Group Ticket"
                      )}
                    </button>
                    <Popconfirm
                      title="Refresh tickets?"
                      onConfirm={() => {
                        this.handleTicketRefresh();
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                      style={{
                        alignSelf: "flex-end",
                        backgroundColor: "#999999",
                        borderColor: "#999999",
                        color: "#ffffff",
                      }}
                    >
                      <Button
                        type="primary"
                        style={{
                          alignSelf: "flex-end",
                          backgroundColor: "#999999",
                          borderColor: "#999999",
                          color: "#ffffff",
                        }}
                      >
                        {isMobile() ? (
                          <ReloadOutlined style={{ fontSize: "20px" }} />
                        ) : (
                          "Refresh"
                        )}
                      </Button>
                    </Popconfirm>
                  </div>
                </li>
              )}
            </ul>
          </div>

          {this.props.router.params.tab === "all" && (
            <Table
              bordered
              columns={columns}
              expandIconAsCell={true}
              expandable={{
                expandedRowRender: (record) => ticketsExpandedRow(record),
              }}
              loading={this.state.isLoading}
              bodyStyle={{
                fontSize: window.screen.width > 500 ? "18px" : "12px",
                backgroundColor: "#ffffff",
              }}
              className="table-backdrop mt-3"
              dataSource={this.state.tableData}
              pagination={false}
              onChange={this.handleTableChange}
              rowKey="id"
              key="id"
              size="middle"
              scroll={{ x: "max-content" }}
            />
          )}
          {this.props.router.params.tab === "update" && (
            <div style={{ marginTop: 20 }}>
              <p>
                Click the button below to refresh the schedule in the Tickets
                section of the app (if missing future matches or has incorrect
                dates/times). If Tickets section is the same as Schedule (and
                both appear incorrectly) the underlying match feed is incorrect.
                Check back in a few days (usually updated in a timely manner) or
                reach out to support@chant.fan.
              </p>
              <Button
                type="primary"
                className="default-text-color"
                style={{
                  backgroundColor: primaryColor,
                  borderColor: primaryColor,
                }}
                onClick={() => {
                  let msg = message.loading("Updating matches and tickets");
                  axios
                    .get(
                      `https://us-central1-chant2019.cloudfunctions.net/addMatches?groupId=${this.props.currentGroup}&session=${moment().year()}`
                    )
                    .then((res) => {
                      msg();
                      message.success("Updated Successfully");
                    });
                }}
              >
                Update Match Listing
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.tab === "past") {
      if (this.props.currentGroup !== prevProps.currentGroup) {
        this.fetchData(Date.now());
      }
      if (!this.props.adminData[`${this.state.tab}Tickets`]) {
        this.fetchData(Date.now());
      }
      if (
        this.props.adminData[`${this.state.tab}Tickets`] &&
        this.props.adminData[`${this.state.tab}Tickets`].records !==
          this.state.pagination.total &&
        this.props.adminData[`${this.state.tab}Tickets`].records !== undefined
      ) {
        this.fetchData(Date.now());
        this.setState((prevState) => {
          let pagination = { ...prevState.pagination };
          pagination.total =
            this.props.adminData[`${this.state.tab}Tickets`].records;
          pagination.defaultPageSize = 10;
          return { pagination, tableData: [] };
        });
      }
    } else {
      var adminData = this.props.adminData[`${this.state.tab}Tickets`];
      var paginationTotal = this.state.pagination.total;
      if (this.props.currentGroup !== prevProps.currentGroup) {
        this.fetchData();
      }
      if (
        adminData &&
        adminData.records !== paginationTotal &&
        adminData.records !== undefined
      ) {
        this.fetchData();
        this.setState((prevState) => {
          let pagination = { ...prevState.pagination };
          pagination.total =
            this.props.adminData[`${this.state.tab}Tickets`].records;
          pagination.defaultPageSize = 10;
          return { pagination, tableData: [] };
        });
      }
    }

    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.props.dispatch(startAddMembers(this.props.currentGroup));
      this.changeHeaderColor();
    }
  }

  componentDidMount() {
    this.fetchData();
    this.props.dispatch(startAddMembers(this.props.currentGroup));
    this.changeHeaderColor();
  }

  async fetchData(date = null) {
    const groupId = this.props.currentGroup;
    const tab = this.state.tab;

    await axios
      .get(
        "https://us-central1-chant2019.cloudfunctions.net/getAllGroupTicket?groupId=" +
          groupId
      )
      .then((tickets) => {
        if (tickets?.data?.body?.length > 0) {
          this.props.dispatch(
            setGettingStartedItemStatus(
              groupId,
              GUIDE_ENGAGE,
              TOPIC_SELL_TICKETS,
              true
            )
          );
        }

        if (date === null) {
          tickets = tickets.data.body.filter((ticket) => {
            return (
              (ticket.data.groupTicket.publishStatus === "live" ||
                ticket.data.groupTicket.publishStatus === "saved") &&
              moment
                .unix(ticket.data.groupTicket.eventDate)
                .utc()
                .local()
                .format("YYYY-MM-DD") >= new Date().toISOString().slice(0, 10)
              // && (ticket.data.groupTicket.publishStatus==='live'
              //   || ticket.data.groupTicket.publishStatus==='saved')
            );
          });

          tickets = tickets.map((tickets, index) => {
            let newData = new Ticket();
            newData.fromAPIData(tickets);
            let link = `https://group.chant.fan/${this.props.groupName.toLowerCase().replaceAll(" ", "")}/${newData.matchId}/tickets/${newData.id}`;
            return { ...newData, pageId: index, link: link };
          });
        } else {
          tickets = tickets.data.body.filter((ticket) => {
            return (
              ticket.data.groupTicket.publishStatus === "archived" ||
              moment
                .unix(ticket.data.groupTicket.eventDate)
                .utc()
                .local()
                .format("YYYY-MM-DD") < new Date().toISOString().slice(0, 10)
              // || ticket.data.groupTicket.publishStatus==='archived'
              // || ticket.data.groupTicket.publishStatus==='sale-ended'
            );
          });

          tickets = tickets.map((tickets, index) => {
            let newData = new Ticket();
            newData.fromAPIData(tickets);
            let link = `https://group.chant.fan/${this.props.groupName.toLowerCase().replaceAll(" ", "")}/${newData.matchId}/tickets/${newData.id}`;
            return { ...newData, pageId: index, link: link, key: `${index}` };
          });
        }
        this.props.dispatch(startAddTickets(tickets, tab, groupId));

        tickets?.sort(
          (a, b) => b?.groupTicket?.eventDate - a?.groupTicket?.eventDate
        );

        this.setState((prevState) => ({
          tableData: tickets,
          isLoading: false,
          pagination: {
            ...prevState.pagination,
            total: tickets.length,
            position: "top",
            defaultPageSize: 10,
          },
        }));
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    groupName:
      state.adminData.allGroups[state.adminData.currentGroup].data.groupName,
    currentGroup: state.adminData.currentGroup,
    user: state.user,
  };
};

class ExpandIcon extends React.Component {
  state = { expanded: this.props.expanded };
  handleClick = (e) => {
    this.props.onClick(e);
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  render() {
    return (
      <span
        className={`ant-table-row-expand-icon ${!this.state.expanded ? "ant-table-row-collapsed" : "ant-table-row-expanded"}`}
        onClick={this.handleClick}
      ></span>
    );
  }
}

export default connect(mapStateToProps)(withRouter(ListTicket));
